import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import Select2 from "../../../reusable/Select2/Select2"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Territories = () => {

	// const [allRegions, setRegions] = useState({});
 //    const [allTerritoryTypes, setTerritoryTypes] = useState({});
    
	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        name: '',
        territory_unit_num: '',
        logo: '',
        area: '',
        web_link: '',
        population: '',
        lattitude: '',
        longitude: '',
        address: '',
        phone: '',
        mail: '',
        facebook: '',
        twitter: '',
        region_id: '',
        territory_type_id: '',
        parent_id: '',
    });

    const [select2Item, setSelect2Item] = useState({});

    console.log(select2Item)

    const [errors, setErrors] = useState({
        name: {msg: '', isValid: undefined, isInvalid: undefined},
        territory_unit_num: {msg: '', isValid: undefined, isInvalid: undefined},
        logo: {msg: '', isValid: undefined, isInvalid: undefined},
        area: {msg: '', isValid: undefined, isInvalid: undefined},
        web_link: {msg: '', isValid: undefined, isInvalid: undefined},
        population: {msg: '', isValid: undefined, isInvalid: undefined},
        lattitude: {msg: '', isValid: undefined, isInvalid: undefined},
        longitude: {msg: '', isValid: undefined, isInvalid: undefined},
        address: {msg: '', isValid: undefined, isInvalid: undefined},
        phone: {msg: '', isValid: undefined, isInvalid: undefined},
        mail: {msg: '', isValid: undefined, isInvalid: undefined},
        facebook: {msg: '', isValid: undefined, isInvalid: undefined},
        twitter: {msg: '', isValid: undefined, isInvalid: undefined},
        region_id: {msg: '', isValid: undefined, isInvalid: undefined},
        territory_type_id: {msg: '', isValid: undefined, isInvalid: undefined},
        parent_id: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [territoryModal, setTerritoryModal] = useState(false);
	const [territoryId, setTerritoryId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

     // dispatch
    const dispatch = useDispatch()

    const editRegion = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/territories/" + territoryId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let res = await fetch(global.baseUrl + "api/territories/" + territoryId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "territory_unit_num": form.territory_unit_num, "logo": form.logo, "area": form.area, "web_link":  form.web_link, "population":  form.population, "lattitude":  form.lattitude, "longitude":  form.longitude, "address":  form.address, "phone":  form.phone, "mail":  form.mail, "facebook":  form.facebook, "twitter":  form.twitter, "region_id":  form.region_id, "territory_type_id":  form.territory_type_id, "parent_id":  form.parent_id})});
            response = await res.json();


        } else if (modalType === 'create') {

            let res = await fetch(global.baseUrl + "api/territories/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "territory_unit_num": form.territory_unit_num, "logo": form.logo, "area": form.area, "web_link":  form.web_link, "population":  form.population, "lattitude":  form.lattitude, "longitude":  form.longitude, "address":  form.address, "phone":  form.phone, "mail":  form.mail, "facebook":  form.facebook, "twitter":  form.twitter, "region_id":  form.region_id, "territory_type_id":  form.territory_type_id, "parent_id":  form.parent_id})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {


        const target = event.target;
        const name = target.name;

        let value = target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setTerritoryModal(!territoryModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {


            Object.assign(value, {name: item.name});
            Object.assign(value, {territory_unit_num: item.territory_unit_num});
            Object.assign(value, {logo: item.logo ? item.logo : ''});
            Object.assign(value, {area: item.area ? item.area : ''});
            Object.assign(value, {web_link: item.web_link ? item.web_link : ''});
            Object.assign(value, {population: item.population ? item.population : ''});
            Object.assign(value, {lattitude: item.lattitude ? item.lattitude : ''});
            Object.assign(value, {longitude: item.longitude ? item.longitude : ''});
            Object.assign(value, {address: item.address ? item.address : ''});
            Object.assign(value, {phone: item.phone ? item.phone : ''});
            Object.assign(value, {mail: item.mail ? item.mail : ''});
            Object.assign(value, {facebook: item.facebook ? item.facebook : ''});
            Object.assign(value, {twitter: item.twitter ? item.twitter : ''});
            Object.assign(value, {region_id: item.region_id});
            Object.assign(value, {territory_type_id: item.territory_type_id});
            Object.assign(value, {parent_id: item.parent_id});

            setSelect2Item({
                            region: {label: item.region.name, value: item.region_id},
                            territory_type: {label: item.territory_type.type, value: item.territory_type_id},
                            parent: item.parent ? {label: item.parent.name, value: item.parent_id} : null,
                            });
            // setSelect2Item(item);

        } else if (typeOfModal == 'create') {

            Object.assign(value, {name: ''});
            Object.assign(value, {territory_unit_num: ''});
            Object.assign(value, {logo: ''});
            Object.assign(value, {area: ''});
            Object.assign(value, {web_link: ''});
            Object.assign(value, {population: ''});
            Object.assign(value, {lattitude: ''});
            Object.assign(value, {longitude: ''});
            Object.assign(value, {address: ''});
            Object.assign(value, {phone: ''});
            Object.assign(value, {mail: ''});
            Object.assign(value, {facebook: ''});
            Object.assign(value, {twitter: ''});
            Object.assign(value, {region_id: ''});
            Object.assign(value, {territory_type_id: ''});
            Object.assign(value, {parent_id: ''});

            setSelect2Item({
                region: null,
                territory_type: null,
                parent: null,
            });

            console.log(select2Item)
        }


        setValue(value);
    }


	const fields = [
        { key: 'name', _style: { width: '40%'} },
    	{ key: 'territory_unit_num', _style: { width: '40%'} },
    	{ key: 'region.name', sorter: false, _style: { width: '40%'} },
    	{ key: 'territory_type.type', sorter: false, _style: { width: '40%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new territory</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		columnFilter
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
      		pagination
            server_side
            url={global.baseUrl + "api/territories"}
            options={options}
      		scopedSlots = {{
      			'region.name':
	          		(item)=>(
		            	<td>
	                		{item.region.name}
		            	</td>
	          		),
          		'territory_type.type':
	          		(item)=>(
		            	<td>
	                		{item.territory_type.type}
		            	</td>
	          		),
      			'show_details':
                (item, index)=>{
                    return (
                        <td className="py-2">
                            <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={()=>{toggleDetails(index)}}
                            >
                                {details.includes(index) ? 'Hide' : 'Show'}
                            </CButton>
                        </td>
                    )
                },
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                				<CRow>
                					<CCol>
	                					<CCol className="col-12 py-3">
	            							<h4>{item.name}</h4>
	                					</CCol>
	                					<CCol className="col-12">
	            							<p>Territory number: {item.territory_unit_num}</p>
	                					</CCol>
                                        <CCol className="col-12">
                                            <p>Region: {item.region.name}</p>
                                        </CCol>
                                        <CCol className="col-12">
                                            <p>Territory type: {item.territory_type.type}</p>
                                        </CCol>
                					</CCol>
            						<CCol lg="8" className="border py-3 m-0 row">
        							 	<CCol className="col-4">
        							 		{ item.logo && <img className="w-100" src={"http://kojenavlasti.rs/files/logos/" + item.logo}/>}
        							 	</CCol>
        							 	<CCol className="col-8">
        							 		<div>
	            								<span className="font-weight-bold">Email:</span>	{ item.mail && <a href={item.mail}>{item.mail}</a>}
        							 		</div>
        							 		<div>
	            								<span className="font-weight-bold">Facebook:</span>	{ item.facebook && <a href={item.facebook}>{item.facebook}</a>}
        							 		</div>
        							 		<div>
	            								<span className="font-weight-bold">Twitter:</span>	{ item.twitter && <a href={item.twitter}>{item.twitter}</a>}
        							 		</div>
        							 		<div>
	            								<span className="font-weight-bold">Phone:</span>	{ item.phone && item.phone }
        							 		</div>
            							</CCol>
                					</CCol>
                				</CRow>
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setTerritoryId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Territory Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setTerritoryId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={territoryModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete territory' : modalType === 'update' ? 'Update territory' : 'Add new territory'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove territory?' : 
                    <CForm action="" method="post">
                        <CFormGroup>
                            <CLabel htmlFor="name">Territory name</CLabel> <small><i className="text-muted">required</i></small>
                            <CInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder={"Enter Territory name.."}
                            autoComplete="text"
                            value={form.name}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.name.isValid}
                            invalid={errors.name.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.name.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Territory name</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="territory-unit-num">Territory num</CLabel> <small><i className="text-muted">required</i></small>
                            <CInput
                            type="text"
                            id="territory-unit-num"
                            name="territory_unit_num"
                            placeholder={"Enter region num.."}
                            autoComplete="text"
                            value={form.territory_unit_num}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.territory_unit_num.isValid}
                            invalid={errors.territory_unit_num.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.territory_unit_num.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Territory num</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="area">Area</CLabel>
                            <CInput
                            type="number"
                            id="area"
                            name="area"
                            placeholder={"Enter area size.."}
                            autoComplete="text"
                            value={form.area}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.area.isValid}
                            invalid={errors.area.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.area.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Area size in square kilometers...</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="web-link">Web link</CLabel>
                            <CInput
                            type="text"
                            id="web-link"
                            name="web_link"
                            placeholder={"Enter web link.."}
                            autoComplete="text"
                            value={form.web_link}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.web_link.isValid}
                            invalid={errors.web_link.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.web_link.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Website link</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="population">Population</CLabel>
                            <CInput
                            type="text"
                            id="population"
                            name="population"
                            placeholder={"Enter population number.."}
                            autoComplete="text"
                            value={form.population}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.population.isValid}
                            invalid={errors.population.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.population.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Population</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="lattitude">Lattitude</CLabel>
                            <CInput
                            type="text"
                            id="lattitude"
                            name="lattitude"
                            placeholder={"Enter lattitude.."}
                            autoComplete="text"
                            value={form.lattitude}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.lattitude.isValid}
                            invalid={errors.lattitude.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.lattitude.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Lattitude</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="longitude">Longitude</CLabel>
                            <CInput
                            type="text"
                            id="longitude"
                            name="longitude"
                            placeholder={"Enter longitude.."}
                            autoComplete="text"
                            value={form.longitude}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.longitude.isValid}
                            invalid={errors.longitude.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.longitude.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Longitude</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="address">Address</CLabel>
                            <CInput
                            type="text"
                            id="address"
                            name="address"
                            placeholder={"Enter address.."}
                            autoComplete="text"
                            value={form.address}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.address.isValid}
                            invalid={errors.address.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.address.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Address</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="address">Phone</CLabel>
                            <CInput
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder={"Enter phone.."}
                            autoComplete="text"
                            value={form.phone}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.phone.isValid}
                            invalid={errors.phone.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.phone.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Phone</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="mail">Mail</CLabel>
                            <CInput
                            type="text"
                            id="mail"
                            name="mail"
                            placeholder={"Enter mail.."}
                            autoComplete="text"
                            value={form.mail}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.mail.isValid}
                            invalid={errors.mail.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.mail.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Mail</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="facebook">Facebook</CLabel>
                            <CInput
                            type="text"
                            id="facebook"
                            name="facebook"
                            placeholder={"Enter facebook page link.."}
                            autoComplete="text"
                            value={form.facebook}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.facebook.isValid}
                            invalid={errors.facebook.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.facebook.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Facebook</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="twitter">Twitter</CLabel>
                            <CInput
                            type="text"
                            id="twitter"
                            name="twitter"
                            placeholder={"Enter twitter page link.."}
                            autoComplete="text"
                            value={form.twitter}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.twitter.isValid}
                            invalid={errors.twitter.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.twitter.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Twitter</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="region">Region</CLabel>
                            <Select2
                            url={global.baseUrl + "api/regions"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'name'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.region && select2Item.region) }
                            onChange={(value) => {setValue(prev => ({ ...prev, region_id: value, }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="region"
                            />
                            {
                                typeof errors.region_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.region_id.msg}</small>

                            }
                            <CFormText className="help-block">Region</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="territory">Territory type</CLabel>
                            <Select2
                            url={global.baseUrl + "api/types"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'type'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.territory_type && select2Item.territory_type) }
                            onChange={(value) => {setValue(prev => ({ ...prev, territory_type_id: value, }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="territory"
                            />
                            {
                                typeof errors.territory_type_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.territory_type_id.msg}</small>

                            }
                            <CFormText className="help-block">Territory type</CFormText>
                        </CFormGroup>
                       <CFormGroup>
                            <CLabel htmlFor="parent">Parent</CLabel>
                            <Select2
                            url={global.baseUrl + "api/territories"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'name'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.parent && select2Item.parent) }
                            onChange={(value) => {setValue(prev => ({ ...prev, parent_id: value, }));}}
                            setter={setSelect2Item}
                            isClearable
                            keyToUpdate="parent"
                            />
                            {
                                typeof errors.territory_type_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.territory_type_id.msg}</small>

                            }
                            <CFormText className="help-block">Territory type</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editRegion}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Territories