import React from 'react';

// const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));
// const Tables = React.lazy(() => import('./views/base/tables/Tables'));

// const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'));
// const Cards = React.lazy(() => import('./views/base/cards/Cards'));
// const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'));
// const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'));
// const BasicForms = React.lazy(() => import('./views/base/forms/BasicForms'));

// const Jumbotrons = React.lazy(() => import('./views/base/jumbotrons/Jumbotrons'));
// const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'));
// const Navbars = React.lazy(() => import('./views/base/navbars/Navbars'));
// const Navs = React.lazy(() => import('./views/base/navs/Navs'));
// const Paginations = React.lazy(() => import('./views/base/paginations/Pagnations'));
// const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'));
// const ProgressBar = React.lazy(() => import('./views/base/progress-bar/ProgressBar'));
// const Switches = React.lazy(() => import('./views/base/switches/Switches'));

// const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
// const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'));
// const BrandButtons = React.lazy(() => import('./views/buttons/brand-buttons/BrandButtons'));
// const ButtonDropdowns = React.lazy(() => import('./views/buttons/button-dropdowns/ButtonDropdowns'));
// const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'));
// const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'));
// const Charts = React.lazy(() => import('./views/charts/Charts'));
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
// const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
// const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
// const Brands = React.lazy(() => import('./views/icons/brands/Brands'));
// const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
// const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
// const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
// const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
// const Typography = React.lazy(() => import('./views/theme/typography/Typography'));
// const Widgets = React.lazy(() => import('./views/widgets/Widgets'));
// const Users = React.lazy(() => import('./views/users/Users'));
// const User = React.lazy(() => import('./views/users/User'));

import Home from '../components/views/Home'
import Users from '../components/views/Users'
import Profile from '../components/views/Profile'
import Actors from '../components/views/actors/Actors'
import Genders from '../components/views/actors/Genders'
import Professions from '../components/views/actors/Professions'
import Territories from '../components/views/territories/Territories'
import Regions from '../components/views/territories/Regions'
import TerritoryTypes from '../components/views/territories/Types'
import Elections from '../components/views/elections/Elections'
import Levels from '../components/views/elections/Levels'
import ElectionTypes from '../components/views/elections/Types'
import Assemblies from '../components/views/elections/Assemblies'
import Documents from '../components/views/documents/Documents'
import DocumentCategories from '../components/views/documents/DocumentCategories'
import Parties from '../components/views/political/Parties'
import Coalitions from '../components/views/political/Coalitions'
import Functions from '../components/views/political/PoliticalFunctions'
import Statuses from '../components/views/political/PowerStatuses'
import Changes from '../components/views/Changes'

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  { path: '/users', exact: true, name: 'Users', component: Users },
  { path: '/users/all_users', name: 'All users', component: Users },
  { path: '/users/profile', name: 'Profile', component: Profile },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/actors', exact: true, name: 'Actors', component: Actors },
  { path: '/actors/all_actors', name: 'All actors', component: Actors },
  { path: '/actors/genders', name: 'Genders', component: Genders },
  { path: '/actors/professions', name: 'Professions', component: Professions },
  { path: '/territories', exact: true, name: 'Territories', component: Territories },
  { path: '/territories/all_territories', name: 'All territories', component: Territories },
  { path: '/territories/regions', name: 'Regions', component: Regions },
  { path: '/territories/types', name: 'Types', component: TerritoryTypes },
  { path: '/elections', exact: true, name: 'Elections', component: Elections },
  { path: '/elections/all_elections', name: 'All elections', component: Elections },
  { path: '/elections/levels', name: 'Levels', component: Levels },
  { path: '/elections/types', name: 'Types', component: ElectionTypes },
  { path: '/elections/assemblies', name: 'Assemblies', component: Assemblies },
  { path: '/documents', exact: true, name: 'Documents', component: Documents },
  { path: '/documents/all_documents', name: 'All documents', component: Documents },
  { path: '/documents/documents_categories', name: 'Documents categories', component: DocumentCategories },
  { path: '/political', exact: true, name: 'Political', component: Parties },
  { path: '/political/parties', name: 'Parties', component: Parties },
  { path: '/political/coalitions', name: 'Coalitions', component: Coalitions },
  { path: '/political/functions', name: 'Functions', component: Functions },
  { path: '/political/statuses', name: 'Statuses', component: Statuses },
  { path: '/changes', name: 'Changes', component: Changes },
  // { path: '/theme', name: 'Theme', component: Colors, exact: true },
  // { path: '/theme/colors', name: 'Colors', component: Colors },
  // { path: '/theme/typography', name: 'Typography', component: Typography },
  // { path: '/base', name: 'Base', component: Cards, exact: true },
  // { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  // { path: '/base/cards', name: 'Cards', component: Cards },
  // { path: '/base/carousels', name: 'Carousel', component: Carousels },
  // { path: '/base/collapses', name: 'Collapse', component: Collapses },
  // { path: '/base/forms', name: 'Forms', component: BasicForms },
  // { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  // { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  // { path: '/base/navbars', name: 'Navbars', component: Navbars },
  // { path: '/base/navs', name: 'Navs', component: Navs },
  // { path: '/base/paginations', name: 'Paginations', component: Paginations },
  // { path: '/base/popovers', name: 'Popovers', component: Popovers },
  // { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  // { path: '/base/switches', name: 'Switches', component: Switches },
  // { path: '/base/tables', name: 'Tables', component: Tables },
  // { path: '/base/tabs', name: 'Tabs', component: Tabs },
  // { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  // { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  // { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  // { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  // { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  // { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  // { path: '/charts', name: 'Charts', component: Charts },
  // { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  // { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  // { path: '/icons/flags', name: 'Flags', component: Flags },
  // { path: '/icons/brands', name: 'Brands', component: Brands },
  // { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  // { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  // { path: '/notifications/badges', name: 'Badges', component: Badges },
  // { path: '/notifications/modals', name: 'Modals', component: Modals },
  // { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
  // { path: '/widgets', name: 'Widgets', component: Widgets },
  // { path: '/users', exact: true,  name: 'Users', component: Users },
  // { path: '/users/:id', exact: true, name: 'User Details', component: User }
];

export default routes;
