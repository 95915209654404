import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { _loginUser } from '../../../auth/User'

const Login = ({setAppStatus}) => {

    const [form, setValue] = useState({
        login: "",
        password: ""
    });
    const [redirect, setRedirect] = useState(false);

    // dispatch
    const dispatch = useDispatch()


    useEffect(() => {
        if (redirect) {
            return <Redirect to="/"/>;
        }
    }, [redirect])

    // console.log(form)
    // console.log(form.login)

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

    // const setLogin = (value) => {
    //     login = value;
    // }

    // const setPassword = (value) => {
    //     password = value;
    // }

    const handleLogin = async (ev) => {
        ev.preventDefault();

        // console.log(form.login)

        let login = form.login;
        let password = form.password;

        const data = await _loginUser(login, password);

        if (data.message === 'success') {
            setRedirect(true);
            setAppStatus({
                isAuthenticated: true,
                user: data.user
            })
            dispatch({type: 'set', status: {
                isAuthenticated: true,
                user: data.user
            }});
        } else {
            alert("Login Failed!");
        }


        // if (data.token) {

        //     let userData = {
        //         id: data.user.id,
        //         name: data.user.name,
        //         email: data.user.email,
        //         auth_token: data.token,
        //         timestamp: new Date().toString()
        //     };
        //     let appState = {
        //         isAuthenticated: true,
        //         user: userData
        //     };
        //     // save app state with user date in local storage
        //     localStorage["appState"] = JSON.stringify(appState);
        //     onLogin(appState);
                
        // } else {
        //     alert("Login Failed!");
        // }
        // console.log(data.token)

        // if (data.token) {

        // }

        // console.log(data)

        // if (login === undefined || password === undefined) {
        //     alert("Sva polja moraju biti popunjena")
        //     return;
        // }
        // console.log(login, password)
    }

    // if (redirect) {
    //     console.log("redirect")
    //     // history.push('/');
    //     return <Redirect to="/"/>;
    // }

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="5">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm onSubmit={handleLogin}>
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput name="login" type="text" placeholder="Username" autoComplete="username" value={form.login} onChange={(event) => handleInputChange(event)} />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput name="password" type="password" placeholder="Password" autoComplete="current-password" value={form.password} onChange={(event) => handleInputChange(event)} />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton type="submit" color="info" className="px-4">Login</CButton>
                                            </CCol>
                                            <CCol xs="6" className="text-right">
                                                <CButton color="link" className="px-0">Forgot password?</CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default Login
