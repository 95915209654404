import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Types = () => {
    
	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        type: '',
    });

    const [errors, setErrors] = useState({
        type: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [typeModal, setTypeModal] = useState(false);
	const [typeId, setTypeId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

     // dispatch
    const dispatch = useDispatch()

    const editType = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/types/" + typeId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let res = await fetch(global.baseUrl + "api/types/" + typeId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"type": form.type})});
            response = await res.json();


        } else if (modalType === 'create') {

            let res = await fetch(global.baseUrl + "api/types/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"type": form.type})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {


        const target = event.target;
        const name = target.name;

        let value = target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setTypeModal(!typeModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {


            Object.assign(value, {type: item.type});

        } else if (typeOfModal == 'create') {

            Object.assign(value, {type: ''});
        }


        setValue(value);
    }


	const fields = [
    	{ key: 'type', _style: { width: '40%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new type</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
      		pagination
            server_side
            url={global.baseUrl + "api/types"}
            options={options}
      		scopedSlots = {{
        		'show_details':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			<CButton
              				color="primary"
                  			variant="outline"
                  			shape="square"
                  			size="sm"
                  			onClick={()=>{toggleDetails(index)}}
                			>
                  				{details.includes(index) ? 'Hide' : 'Show'}
                			</CButton>
              			</td>
              		)
          		},
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setTypeId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Type Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setTypeId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={typeModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete type' : modalType === 'update' ? 'Update type' : 'Add new type'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove territory type?' : 
                    <CForm action="" method="post">
                        <CFormGroup>
                            <CLabel htmlFor="type">Type</CLabel>
                            <CInput
                            type="text"
                            id="type"
                            name="type"
                            placeholder={"Enter Type.."}
                            autoComplete="text"
                            value={form.type}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.type.isValid}
                            invalid={errors.type.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.type.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Type</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editType}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Types