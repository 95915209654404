import { useSelector, useDispatch } from 'react-redux'
// import '../conf/globals.js'

export const _loginUser = async (login, password) => {
  // console.log(login)
  // console.log(password)
  // var formData = new FormData();
  // formData.append("login", login);
  // formData.append("password", password);
  // console.log(formData);
    const res = await fetch(global.baseUrl + "api/login", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"login": login, "password": password})})
	// const res = await fetch("http://knv.com/public/api/login", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"login": login, "password": password})})
                        // .then(data => console.log(data))
                        // .catch(error => console.log(error))
	const data = await res.json();

    // console.log(data);

    // return;

    





	// console.log(data)
	return await data
    // $("#login-form button")
    // .attr("disabled", "disabled")
    // .html(
    //     '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
    // );

    // axios
    // .post("http://localhost:8000/api/user/login/", formData)
    // .then(response => {
    //     console.log(response);
    //     return response;
    // })
    // .then(json => {
    //     if (json.data.success) {
    //         alert("Login Successful!");

    //         let userData = {
    //             name: json.data.data.name,
    //             id: json.data.data.id,
    //             email: json.data.data.email,
    //             auth_token: json.data.data.auth_token,
    //             timestamp: new Date().toString()
    //         };
    //         let appState = {
    //             isLoggedIn: true,
    //             user: userData
    //         };
    //         // save app state with user date in local storage
    //         localStorage["appState"] = JSON.stringify(appState);
    //         this.setState({
    //             isLoggedIn: appState.isLoggedIn,
    //             user: appState.user
    //         });
    //     } else alert("Login Failed!");

    //     // $("#login-form button")
    //     //     .removeAttr("disabled")
    //     //     .html("Login");
    //     })
    // .catch(error => {
    //     alert(`An Error Occured! ${error}`);
    //     // $("#login-form button")
    //     //     .removeAttr("disabled")
    //     //     .html("Login");
    //     });
};

export const _logoutUser = async () => {

    // dispatch
    // const dispatch = useDispatch()
    // const userUpdate = useSelector(state => state.userUpdate)
    // let appState = {
    //   isLoggedIn: false,
    //   user: {}
    // };
    // // save app state with user date in local storage
    // localStorage["appState"] = JSON.stringify(appState);
    // this.setState(appState);
    const res = await fetch(global.baseUrl + "api/logout", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include'})
    // const res = await fetch("http://knv.com/public/api/login", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"login": login, "password": password})})
                        // .then(data => console.log(data))
                        // .catch(error => console.log(error))
    const data = await res.json();

    // clean User context
    // dispatch({type: 'set', userUpdate: {}})

    // console.log(data);

    // return;

    





    // console.log(data)
    return await data
};







// export const _registerUser = (name, email, password) => {
//     $("#email-login-btn")
//       .attr("disabled", "disabled")
//       .html(
//         '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
//       );

//     var formData = new FormData(); 
//     formData.append("password", password);
//     formData.append("email", email);
//     formData.append("name", name);

//     axios
//       .post("http://localhost:8000/api/user/register", formData)
//       .then(response => {
//         console.log(response);
//         return response;
//       })
//       .then(json => {
//         if (json.data.success) {
//           alert(`Registration Successful!`);

//           let userData = {
//             name: json.data.data.name,
//             id: json.data.data.id,
//             email: json.data.data.email,
//             auth_token: json.data.data.auth_token,
//             timestamp: new Date().toString()
//           };
//           let appState = {
//             isLoggedIn: true,
//             user: userData
//           };
//           // save app state with user date in local storage
//           localStorage["appState"] = JSON.stringify(appState);
//           this.setState({
//             isLoggedIn: appState.isLoggedIn,
//             user: appState.user
//           });
//         } else {
//           alert(`Registration Failed!`);
//           $("#email-login-btn")
//             .removeAttr("disabled")
//             .html("Register");
//         }
//       })
//       .catch(error => {
//         alert("An Error Occured!" + error);
//         console.log(`${formData} ${error}`);
//         $("#email-login-btn")
//           .removeAttr("disabled")
//           .html("Register");
//       });
//   };