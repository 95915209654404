import React from 'react'
import { useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const _sidebar_nav =  [
  	{
	    _tag: 'CSidebarNavItem',
	    name: 'Dashboard',
	    to: '/dashboard',
	    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>,
	    badge: {
	      color: 'info',
	      text: 'NEW',
	    }
  	},
  	{
	    _tag: 'CSidebarNavTitle',
	    _children: ['KNV Managment']
  	},
  	{
        _tag: 'CSidebarNavItem',
        name: 'Changes',
        to: '/changes',
        icon: <FontAwesomeIcon icon="exchange-alt"  className="c-sidebar-nav-icon"/>,
  	},
  	{
	    _tag: 'CSidebarNavDropdown',
	    name: 'Actors',
	    route: '/actors',
	    icon: <FontAwesomeIcon icon="user-friends"  className="c-sidebar-nav-icon"/>,
	    _children: [
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'All actors',
		        to: '/actors/all_actors',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Genders',
		        to: '/actors/genders',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Professions',
		        to: '/actors/professions',
	      	},
    	]
  	},
  	{
	    _tag: 'CSidebarNavDropdown',
	    name: 'Territories',
	    route: '/territories',
	    icon: <FontAwesomeIcon icon="map-marked-alt"  className="c-sidebar-nav-icon"/>,
	    _children: [
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'All territories',
		        to: '/territories/all_territories',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Regions',
		        to: '/territories/regions',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Types',
		        to: '/territories/types',
	      	}
    	]
  	},
  	{
	    _tag: 'CSidebarNavDropdown',
	    name: 'Elections',
	    route: '/elections',
	    icon: <FontAwesomeIcon icon="vote-yea"  className="c-sidebar-nav-icon"/>,
	    _children: [
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'All elections',
		        to: '/elections/all_elections',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Levels',
		        to: '/elections/levels',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Types',
		        to: '/elections/types',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Assemblies',
		        to: '/elections/assemblies',
	      	}
    	]
  	},
  	{
	    _tag: 'CSidebarNavDropdown',
	    name: 'Documents',
	    route: '/documents',
	    icon: <FontAwesomeIcon icon="file"  className="c-sidebar-nav-icon"/>,
	    _children: [
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'All documents',
		        to: '/documents/all_documents',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Documents categories',
		        to: '/documents/documents_categories',
	      	},
    	]
  	},
  	{
	    _tag: 'CSidebarNavDropdown',
	    name: 'Political',
	    route: '/political',
	    icon: <FontAwesomeIcon icon="handshake"  className="c-sidebar-nav-icon"/>,
	    _children: [
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Parties',
		        to: '/political/parties',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Coalitions',
		        to: '/political/coalitions',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Functions',
		        to: '/political/functions',
	      	},
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Power statuses',
		        to: '/political/statuses',
	      	},
    	]
  	},
  	{
	    _tag: 'CSidebarNavTitle',
	    _children: ['App Managment']
  	},
  	{
	  	permission_in: 'Administrate',
	    _tag: 'CSidebarNavDropdown',
	    name: 'Users',
	    route: '/users',
	    icon: <FontAwesomeIcon icon="users"  className="c-sidebar-nav-icon"/>,
	    _children: [
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'All users',
		        to: '/users/all_users',
	      	},
	      // {
	      //   _tag: 'CSidebarNavItem',
	      //   name: 'Add new user',
	      //   to: '/add_users',
	      // },
	      	{
		        _tag: 'CSidebarNavItem',
		        name: 'Profile',
		        to: '/users/profile',
	      	},
    	]
  	},
  	{
	  	permission_not_in: 'Administrate',
        _tag: 'CSidebarNavItem',
        name: 'Profile',
        to: '/profile',
      
	    // _tag: 'CSidebarNavDropdown',
	    // name: 'Users',
	    // route: '/users',
	    // icon: 'cil-user',
	    // _children: [
	    //   	{
		   //      _tag: 'CSidebarNavItem',
		   //      name: 'All users',
		   //      to: '/users/all_users',
	    //   	},
	    //   // {
	    //   //   _tag: 'CSidebarNavItem',
	    //   //   name: 'Add new user',
	    //   //   to: '/add_users',
	    //   // },
	    //   	{
		   //      _tag: 'CSidebarNavItem',
		   //      name: 'Profile',
		   //      to: '/users/profile',
	    //   	},
    	// ]
  	},
  	{
    	_tag: 'CSidebarNavDropdown',
    	name: 'Permissions&Roles',
    	route: '/permissions_roles',
    	icon: <FontAwesomeIcon icon="user-lock"  className="c-sidebar-nav-icon"/>,
    	_children: [
	      	{
	        	_tag: 'CSidebarNavItem',
	        	name: 'Permissions',
	        	to: '/permissions',
	      	},
	      	{
	        	_tag: 'CSidebarNavItem',
	        	name: 'Roles',
	        	to: '/roles',
	      	}
    	]
  	}
]

export const SidebarConf = (user_permissions) => {

	let roles;
	let _sidebar_nav_final = [];

	if (typeof user_permissions !== 'undefined') {
		_sidebar_nav.map((column, index) => {
			if (typeof column.permission_in !== 'undefined') {

				if (user_permissions.includes(column.permission_in)) {
					_sidebar_nav_final.push(column);
				}
			}

			if (typeof column.permission_not_in !== 'undefined') {
				
				if (!user_permissions.includes(column.permission_not_in)) {
					_sidebar_nav_final.push(column);
				}
			}

			if (typeof column.permission_in === 'undefined' && typeof column.permission_not_in === 'undefined') {
				_sidebar_nav_final.push(column);
			}
		})
	}





	return _sidebar_nav_final;
}

// export default Conf