import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
    CTextarea,
    CSelect,
    CInputFile
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import Select2 from "../../../reusable/Select2/Select2"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Documents = () => {

	// const [actors, setActors] = useState();
    // const [all_genders, setGenders] = useState({});
    // const [all_professions, setProfessions] = useState({});

	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        name: '',
        file: '',
        document_category_id: '',
        territory_id: '',
    });

    const[filename, setFilename] = useState('Choose file...');

    // const [select2Values, setSelect2Values] = useState({
    //     gender: null,
    //     profession: null,
    // })
    const [select2Item, setSelect2Item] = useState({});

    const [errors, setErrors] = useState({
        name: {msg: '', isValid: undefined, isInvalid: undefined},
        file: {msg: '', isValid: undefined, isInvalid: undefined},
        document_category_id: {msg: '', isValid: undefined, isInvalid: undefined},
        territory_id: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [documentModal, setDocumentModal] = useState(false);
	const [documentId, setDocumentId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

     // dispatch
    const dispatch = useDispatch()

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const editDocument = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/documents/" + documentId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            console.log(form)

            let formData = new FormData();

            formData.append('name', form.name);
            formData.append('file', form.file);
            formData.append('document_category_id', form.document_category_id);
            formData.append('territory_id', form.territory_id);

            // let fileData = await toBase64(form.file);

            let res = await fetch(global.baseUrl + "api/documents/" + documentId, {method: 'POST', credentials: 'include', body: formData});
            // let res = await fetch(global.baseUrl + "api/documents/" + documentId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "file": form.file, filename: form.file.name,document_category_id: form.document_category_id, territory_id: form.territory_id})});
            response = await res.json();


        } else if (modalType === 'create') {

            let formData = new FormData();

            formData.append('name', form.name);
            formData.append('file', form.file);
            formData.append('document_category_id', form.document_category_id);
            formData.append('territory_id', form.territory_id);

            // let fileData = await toBase64(form.file);

            let res = await fetch(global.baseUrl + "api/documents/add", {method: 'POST', credentials: 'include', body: formData});
            // let res = await fetch(global.baseUrl + "api/documents/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "file": fileData, filename: form.file.name, document_category_id: form.document_category_id, territory_id: form.territory_id})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {

        console.log(event)

        const target = event.target;
        const name = target.name;

        let value = target.files ? target.files[0] : target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));

        if (target.files) {
            setFilename(target.files[0].name);
        }
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setDocumentModal(!documentModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {

            Object.assign(value, {name: item.name});
            Object.assign(value, {file: item.file});
            Object.assign(value, {document_category_id: item.document_category_id});
            Object.assign(value, {territory_id: item.territory_id});

            setSelect2Item({
                document_category: {label: item.document_category.name, value: item.document_category_id},
                territory: {label: item.territory.name, value: item.territory_id}
            })

            setFilename(item.filename);

        } else if (typeOfModal == 'create') {

            Object.assign(value, {name: ''});
            Object.assign(value, {file: ''});
            Object.assign(value, {document_category_id: ''});
            Object.assign(value, {territory_id: ''});

            setSelect2Item({
                document_category: null,
                territory: null
            })
            setFilename('Choose file...');
        }


        setValue(value);
    }


	const fields = [
    	{ key: 'name', _style: { width: '40%'} },
        { key: 'filename', _style: { width: '40%'} },
        { key: 'territory.name', _style: { width: '40%'} },
    	{ key: 'document_category.name', _style: { width: '40%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new document</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		columnFilter
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
      		pagination
            server_side
            url={global.baseUrl + "api/documents"}
            options={options}
      		scopedSlots = {{
                'territory.name':
                (item, index)=>{
                    return (
                        <td className="py-2">
                            {item.territory.name}
                        </td>
                    )
                },
                'document_category.name':
                (item, index)=>{
                    return (
                        <td className="py-2">
                            {item.document_category.name}
                        </td>
                    )
                },
        		'show_details':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			<CButton
              				color="primary"
                  			variant="outline"
                  			shape="square"
                  			size="sm"
                  			onClick={()=>{toggleDetails(index)}}
                			>
                  				{details.includes(index) ? 'Hide' : 'Show'}
                			</CButton>
              			</td>
              		)
          		},
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                				{//<CRow>
                                                                    // <CCol>
                                                                        // <CCol className="col-12 py-3">
                                                                            // {<h4>{item.first_name + ' ' + item.last_name} <FontAwesomeIcon icon={item.gender_icon} /></h4>}
                                                                        // </CCol>
                                                                        // <CCol className="col-12">
                                                                            // <h5>Birth year: {item.birth_year}</h5>
                                                                        // </CCol>
                                                                        // <CCol className="col-12">
                                                                            // <h5>Profession: {item.profession || '/'}</h5>
                                                                        // </CCol>
                                                                    // </CCol>
                                                                    // <CCol lg="8" className="border py-3 my-2">
                                                                        // <h5>Biography:</h5>
                                                                        // {item.biography}
                                                                    // </CCol>
                                                                //</CRow>
                                                            }
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setDocumentId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Document Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setDocumentId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={documentModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete document' : modalType === 'update' ? 'Update document' : 'Add new document'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove document?' : 
                    <CForm action="" method="post">
                        <CFormGroup>
                            <CLabel htmlFor="name">Name</CLabel>
                            <CInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder={"Enter Document name.."}
                            autoComplete="text"
                            value={form.name}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.name.isValid}
                            invalid={errors.name.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.name.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Name</CFormText>
                        </CFormGroup>
                        <CFormGroup row className="mx-0">
                            <CLabel col md={3} className="pl-0">Upload</CLabel>
                            <CCol xs="12" md="9">
                                <CInputFile 
                                id="file" 
                                name="file"
                                filename="test"
                                // value={form.file}
                                onChange={(event) => handleInputChange(event)}
                                custom
                                />
                                <CLabel htmlFor="file" variant="custom-file">
                                    {filename}
                                </CLabel>
                                {
                                    typeof errors.file === 'undefined' ?

                                    <small className="text-success">Input is valid!</small>
                                    :
                                    <small className="text-danger">{errors.file.msg}</small>

                                }
                            </CCol>
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.file.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Upload</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="document-category">Category</CLabel>
                            <Select2
                            url={global.baseUrl + "api/document_categories"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'name'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.document_category && select2Item.document_category) }
                            onChange={(value) => {setValue(prev => ({ ...prev, document_category_id: value, }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="document_category"
                            />
                            {
                                typeof errors.document_category_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.document_category_id.msg}</small>

                            }
                            <CFormText className="help-block">Category</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="territory">Territory</CLabel>
                            <Select2
                            url={global.baseUrl + "api/territories"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'name'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.territory && select2Item.territory) }
                            onChange={(value) => {setValue(prev => ({ ...prev, territory_id: value }));}}
                            setter={setSelect2Item}
                            isClearable
                            keyToUpdate="territory"
                            />
                            {
                                typeof errors.territory_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.territory_id.msg}</small>

                            }
                            <CFormText className="help-block">Territory</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editDocument}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Documents