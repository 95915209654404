import React, {useState, useEffect, useContext} from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    CBadge,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { _logoutUser } from '../../../auth/User'

const UserHeaderDropdown = ({setAppStatus}) => {

    const [headerStatus, setHeaderStatus] = useState();

    // console.log(headerStatus)

    // const { status } = useContext(StatusContext);

    // dispatch
    const dispatch = useDispatch()
    const status = useSelector(state => state.status)
    // const statusTouch = useSelector(state => state.statusTouch)

    // console.log('header user render')
    // console.log(status)

    const handleClick = () => {
        _logoutUser();

        setAppStatus({
            isAuthenticated: false,
            user: {}
        })
    }

    useEffect(() => {
        // if (typeof status !== 'undefined') {
            setHeaderStatus(status);
        // }
    }, [status])

    return (
        <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
            <CDropdownToggle className="c-header-nav-link" caret={true}>
                {<h6>{typeof headerStatus !== 'undefined' && headerStatus.user.name}</h6>}
                {/*<h6>{status.user.name}</h6>*/}
                {/*<div className="c-avatar">
                  <CImg
                    src={'avatars/default_user.svg'}
                    alt="User avatar."
                    width="24px"
                    height="24px"
                    className="text-secondary"
                  />
                </div>*/}
            </CDropdownToggle>
            <CDropdownMenu className="pt-0" placement="bottom-end">
                <CDropdownItem header tag="div" color="light" className="text-center">
                    <strong>Account</strong>
                </CDropdownItem>
                <CDropdownItem onClick={handleClick}>
                    <CIcon name="cil-account-logout" className="mfe-2"/>Logout
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    )
}

export default UserHeaderDropdown
