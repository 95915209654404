let baseUrl;

if (window.location.host == "data.kojenavlasti.rs" || window.location.host == "admin.kojenavlasti.rs" ) {
	baseUrl = "https://data.kojenavlasti.rs/";
} else {
	baseUrl = "http://localhost/knv_b/public/";
	// baseUrl = "http://knv.com/";
}

// const globals = {baseUrl: tmpBaseUrl};


// export default globals;

global.baseUrl = baseUrl;