import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Regions = () => {
    
	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        name: '',
        region_num: '',
    });

    const [errors, setErrors] = useState({
        name: {msg: '', isValid: undefined, isInvalid: undefined},
        region_num: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [regionModal, setRegionModal] = useState(false);
	const [regionId, setRegionId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

     // dispatch
    const dispatch = useDispatch()

    const editRegion = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/regions/" + regionId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let res = await fetch(global.baseUrl + "api/regions/" + regionId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "region_num": form.region_num !== '' ? form.region_num : null})});
            response = await res.json();


        } else if (modalType === 'create') {

            let res = await fetch(global.baseUrl + "api/regions/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "region_num":  form.region_num !== '' ? form.region_num : null})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {


        const target = event.target;
        const name = target.name;

        let value = target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setRegionModal(!regionModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {


            Object.assign(value, {name: item.name});
            Object.assign(value, {region_num: item.region_num ? item.region_num : ''});

        } else if (typeOfModal == 'create') {

            Object.assign(value, {name: ''});
            Object.assign(value, {region_num: ''});
        }


        setValue(value);
    }


	const fields = [
        { key: 'name', _style: { width: '40%'} },
    	{ key: 'region_num', _style: { width: '40%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new region</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
      		pagination
            server_side
            url={global.baseUrl + "api/regions"}
            options={options}
      		scopedSlots = {{
        		'region_num':
          		(item, index)=>{
            		return (
              			<td>{item.region_num ? item.region_num : ''}</td>
              		)
          		},
                'show_details':
                (item, index)=>{
                    return (
                        <td className="py-2">
                            <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={()=>{toggleDetails(index)}}
                            >
                                {details.includes(index) ? 'Hide' : 'Show'}
                            </CButton>
                        </td>
                    )
                },
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setRegionId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Region Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setRegionId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={regionModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete region' : modalType === 'update' ? 'Update region' : 'Add new region'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove region?' : 
                    <CForm action="" method="post">
                        <CFormGroup>
                            <CLabel htmlFor="region">Region name</CLabel> <small><i className="text-muted">required</i></small>
                            <CInput
                            type="text"
                            id="region"
                            name="name"
                            placeholder={"Enter Region.."}
                            autoComplete="text"
                            value={form.name}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.name.isValid}
                            invalid={errors.name.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.name.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Region name</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="region-num">Region number</CLabel>
                            <CInput
                            type="text"
                            id="region-num"
                            name="region_num"
                            placeholder={"Enter region num.."}
                            autoComplete="text"
                            value={form.region_num}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.region_num.isValid}
                            invalid={errors.region_num.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.region_num.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Region number</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editRegion}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Regions