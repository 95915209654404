import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
    CTextarea,
    CSelect
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import Select2 from "../../../reusable/Select2/Select2"
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Assemblies = () => {

	// const [actors, setActors] = useState();
    // const [all_genders, setGenders] = useState({});
    // const [all_professions, setProfessions] = useState({});

	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        from: null,
        to: null,
        election_id: '',
        territory_id: '',
    });

    // const [select2Values, setSelect2Values] = useState({
    //     gender: null,
    //     profession: null,
    // })
    const [select2Item, setSelect2Item] = useState({});

    const [errors, setErrors] = useState({
        from: {msg: '', isValid: undefined, isInvalid: undefined},
        to: {msg: '', isValid: undefined, isInvalid: undefined},
        election_id: {msg: '', isValid: undefined, isInvalid: undefined},
        territory_id: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [assemblyModal, setAssemblyModal] = useState(false);
	const [assemblyId, setAssemblyId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

     // dispatch
    const dispatch = useDispatch()

    const editAssembly = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/assemblies/" + assemblyId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let res = await fetch(global.baseUrl + "api/assemblies/" + assemblyId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"from": form.from, "to": form.to, election_id: form.election_id, territory_id: form.territory_id})});
            response = await res.json();


        } else if (modalType === 'create') {

            let res = await fetch(global.baseUrl + "api/assemblies/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"from": form.from, "to": form.to, election_id: form.election_id, territory_id: form.territory_id})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {

        console.log(event)

        const target = event.target;
        const name = target.name;

        let value = target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setAssemblyModal(!assemblyModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {

            Object.assign(value, {from: item.from});
            Object.assign(value, {to: item.to});
            Object.assign(value, {election_id: item.election_id});
            Object.assign(value, {territory_id: item.territory_id});

        	setFrom(new Date(item.from));
        	setTo(item.to ? new Date(item.to) : null);

            setSelect2Item({
                election: {label: item.election.title, value: item.election_id},
                territory: {label: item.territory.name, value: item.territory_id}
            })

        } else if (typeOfModal == 'create') {

            Object.assign(value, {from: null});
            Object.assign(value, {to: null});
            Object.assign(value, {election_id: ''});
            Object.assign(value, {territory_id: ''});

            setFrom(null);
        	setTo(null);

            setSelect2Item({
                election: null,
                territory: null
            })
        }


        setValue(value);
    }


	const fields = [
    	{ key: 'territory.name', _style: { width: '20%'} },
    	{ key: 'election.title', _style: { width: '20%'} },
    	{ key: 'from', _style: { width: '20%'} },
    	{ key: 'to', _style: { width: '20%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new assembly</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		columnFilter
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
      		pagination
            server_side
            url={global.baseUrl + "api/assemblies"}
            options={options}
      		scopedSlots = {{
      			'territory.name':
          		(item, index)=>{
            		return (
              			<td>
                			{item.territory.name}
              			</td>
              		)
          		},
          		'election.title':
          		(item, index)=>{
            		return (
              			<td>
                			{item.election.title}
              			</td>
              		)
          		},
                'from':
                (item, index)=>{
                    return (
                        <td>
                            {moment(item.from).format('DD.MM.YYYY.')}
                        </td>
                    )
                },
          		'to':
          		(item, index)=>{
            		return (
              			<td>
                			{item.to ? moment(item.to).format('DD.MM.YYYY.') : ''}
              			</td>
              		)
          		},
        		'show_details':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			<CButton
              				color="primary"
                  			variant="outline"
                  			shape="square"
                  			size="sm"
                  			onClick={()=>{toggleDetails(index)}}
                			>
                  				{details.includes(index) ? 'Hide' : 'Show'}
                			</CButton>
              			</td>
              		)
          		},
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setAssemblyId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Assembly Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setAssemblyId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={assemblyModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete assembly' : modalType === 'update' ? 'Update assembly' : 'Add new assembly'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove assembly?' : 
                    <CForm action="" method="post">
                    	<CFormGroup>
                            <CLabel htmlFor="from">From</CLabel>
                            <div className="knv-react-datepicker-wrapper">
	                            <DatePicker
	                            id="from"
	                        	name="from"
				      			selected={from}
						      	onChange={(date) => {setFrom(date);setValue(prev => ({...prev, from: moment(date).format('YYYY-MM-DD')}));}}
						      	// showYearPicker
						      	dateFormat="dd.MM.yyyy."
						      	className="form-control w-100"
							    />
                            </div>
                        	<div>
                        	{
                        		typeof errors.from === 'undefined' ?

                            	<small className="text-success">Input is valid!</small>
                        		:
                            	<small className="text-danger">{errors.from.msg}</small>

                        	}
                        	</div>
                            <CFormText className="help-block">From</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="to">To</CLabel>
                            <div className="knv-react-datepicker-wrapper">
	                            <DatePicker
	                            id="to"
	                        	name="to"
				      			selected={to}
						      	onChange={(date) => {setTo(date);setValue(prev => ({...prev, to: date ? moment(date).format('YYYY-MM-DD') : null}));}}
						      	// showYearPicker
						      	dateFormat="dd.MM.yyyy."
						      	className="form-control w-100"
							    />
                            </div>
                        	<div>
                        	{
                        		typeof errors.to === 'undefined' ?

                            	<small className="text-success">Input is valid!</small>
                        		:
                            	<small className="text-danger">{errors.to.msg}</small>

                        	}
                        	</div>
                            <CFormText className="help-block">To</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="gender">Election</CLabel>
                            <Select2
                            url={global.baseUrl + "api/elections"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'title'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.election && select2Item.election) }
                            onChange={(value) => {setValue(prev => ({ ...prev, election_id: value, }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="election"
                            />
                            {
                                typeof errors.election_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.election_id.msg}</small>

                            }
                            <CFormText className="help-block">Election</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="profession">Territory</CLabel>
                            <Select2
                            url={global.baseUrl + "api/territories"}
                            options={{credentials: 'include', body: {territory_type_id: '1,3', name_only: true}}}
                            customKeys={{key:'id', label:'name'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.territory && select2Item.territory) }
                            onChange={(value) => {setValue(prev => ({ ...prev, territory_id: value }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="territory"
                            />
                            {
                                typeof errors.territory_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.territory_id.msg}</small>

                            }
                            <CFormText className="help-block">Territory</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editAssembly}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Assemblies