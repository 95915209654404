export const logo = ['215 40', `
  	<title>logo</title>
  	<g>
		<g>
			<path fill="#3A5967" d="M1.264,12.692h3.044v5.741l5.411-5.741h3.801l-5.411,5.469l5.935,8.377h-3.82l-4.17-6.225l-1.746,1.765    v4.46H1.264V12.692z"/>
			<path fill="#3A5967" d="M15.152,19.674c0-1.035,0.155-1.995,0.466-2.88c0.31-0.885,0.756-1.658,1.338-2.317    c0.582-0.659,1.296-1.177,2.143-1.552c0.847-0.375,1.8-0.562,2.861-0.562c1.073,0,2.03,0.188,2.87,0.562    c0.84,0.375,1.551,0.892,2.133,1.552c0.582,0.659,1.028,1.432,1.338,2.317c0.31,0.886,0.465,1.846,0.465,2.88    c0,1.008-0.155,1.949-0.465,2.822c-0.311,0.873-0.756,1.633-1.338,2.279s-1.293,1.153-2.133,1.521    c-0.84,0.369-1.797,0.553-2.87,0.553c-1.061,0-2.014-0.184-2.861-0.553c-0.847-0.368-1.561-0.875-2.143-1.521    c-0.582-0.646-1.028-1.406-1.338-2.279C15.308,21.623,15.152,20.683,15.152,19.674z M18.197,19.674    c0,0.582,0.068,1.147,0.204,1.697c0.136,0.549,0.353,1.044,0.65,1.483c0.297,0.44,0.685,0.792,1.164,1.058    c0.479,0.265,1.06,0.396,1.746,0.396c0.685,0,1.267-0.132,1.745-0.396c0.479-0.266,0.866-0.617,1.164-1.058    c0.297-0.439,0.514-0.935,0.65-1.483c0.136-0.549,0.204-1.115,0.204-1.697c0-0.608-0.068-1.196-0.204-1.765    s-0.353-1.076-0.65-1.522c-0.297-0.446-0.685-0.801-1.164-1.066c-0.478-0.265-1.06-0.398-1.745-0.398    c-0.686,0-1.267,0.133-1.746,0.398s-0.866,0.621-1.164,1.066c-0.297,0.446-0.514,0.954-0.65,1.522S18.197,19.066,18.197,19.674z"/>
			<path fill="#3A5967" d="M45.314,22.176c0,0.453-0.045,0.951-0.136,1.494s-0.297,1.05-0.621,1.521    c-0.323,0.473-0.789,0.867-1.396,1.184c-0.607,0.316-1.429,0.475-2.463,0.475c-0.582,0-1.144-0.08-1.688-0.242    c-0.543-0.162-1.028-0.414-1.454-0.756c-0.427-0.344-0.77-0.783-1.028-1.319c-0.258-0.536-0.388-1.187-0.388-1.949v-1.202h2.754    v0.621c0,0.336,0.026,0.646,0.078,0.93c0.052,0.285,0.142,0.527,0.271,0.729c0.129,0.199,0.307,0.358,0.533,0.475    c0.227,0.116,0.521,0.174,0.883,0.174c0.4,0,0.711-0.07,0.931-0.213s0.378-0.326,0.475-0.553c0.097-0.227,0.155-0.472,0.175-0.736    c0.019-0.266,0.029-0.521,0.029-0.767v-9.348h3.045V22.176z"/>
			<path fill="#3A5967" d="M48.478,12.692h10.356v2.56h-7.312v2.967h6.71v2.366h-6.71v3.394h7.467v2.56H48.478V12.692z"/>
			<path fill="#3A5967" d="M67.411,12.692h3.025l5.779,9.29h0.039v-9.29h2.851v13.847H76.06l-5.76-9.27h-0.039v9.27h-2.851V12.692z"/>
			<path fill="#3A5967" d="M86.051,12.692h3.122l5.178,13.847H91.19l-1.047-3.083h-5.178l-1.086,3.083h-3.064L86.051,12.692z     M85.76,21.188h3.588l-1.746-5.082h-0.039L85.76,21.188z"/>
			<path fill="#3A5967" d="M108.357,26.539h-3.432l-4.48-13.847h3.123l3.083,9.735h0.039l3.123-9.735h3.143L108.357,26.539z"/>
			<path fill="#3A5967" d="M114.625,12.692h3.045v11.287h6.749v2.56h-9.794V12.692z"/>
			<path fill="#3A5967" d="M130.395,12.692h3.122l5.179,13.847h-3.162l-1.047-3.083h-5.178l-1.086,3.083h-3.064L130.395,12.692z     M130.104,21.188h3.588l-1.746-5.082h-0.038L130.104,21.188z"/>
			<path fill="#3A5967" d="M142.46,21.943c0,0.465,0.085,0.859,0.253,1.184c0.168,0.322,0.391,0.584,0.668,0.785    c0.278,0.2,0.605,0.349,0.98,0.445c0.375,0.098,0.762,0.146,1.163,0.146c0.271,0,0.563-0.023,0.872-0.068    c0.311-0.045,0.602-0.133,0.873-0.262s0.498-0.308,0.68-0.533c0.181-0.227,0.271-0.514,0.271-0.863c0-0.375-0.12-0.68-0.359-0.912    s-0.553-0.426-0.94-0.582c-0.388-0.154-0.827-0.291-1.319-0.407c-0.49-0.116-0.988-0.246-1.492-0.388    c-0.518-0.129-1.021-0.288-1.514-0.475c-0.491-0.188-0.931-0.43-1.318-0.728c-0.389-0.297-0.701-0.669-0.941-1.115    c-0.238-0.446-0.358-0.986-0.358-1.619c0-0.711,0.152-1.329,0.456-1.852c0.304-0.524,0.701-0.96,1.193-1.309    c0.49-0.349,1.047-0.608,1.667-0.776s1.241-0.252,1.862-0.252c0.724,0,1.419,0.081,2.084,0.242    c0.666,0.162,1.258,0.423,1.775,0.786c0.518,0.362,0.928,0.824,1.231,1.387c0.304,0.562,0.456,1.244,0.456,2.046h-2.948    c-0.026-0.414-0.113-0.756-0.262-1.028c-0.149-0.271-0.347-0.485-0.592-0.64s-0.526-0.265-0.844-0.33    c-0.316-0.064-0.663-0.097-1.038-0.097c-0.245,0-0.49,0.026-0.736,0.078s-0.469,0.142-0.669,0.271s-0.365,0.291-0.495,0.485    c-0.129,0.194-0.193,0.439-0.193,0.737c0,0.272,0.052,0.492,0.155,0.66c0.103,0.168,0.307,0.323,0.61,0.465    c0.305,0.142,0.725,0.285,1.261,0.427s1.238,0.323,2.104,0.543c0.258,0.052,0.617,0.146,1.076,0.281s0.915,0.353,1.367,0.65    c0.453,0.297,0.844,0.695,1.174,1.192c0.33,0.498,0.494,1.135,0.494,1.91c0,0.634-0.123,1.223-0.368,1.766    c-0.246,0.543-0.611,1.012-1.097,1.406c-0.484,0.394-1.086,0.701-1.803,0.92c-0.718,0.221-1.549,0.33-2.492,0.33    c-0.764,0-1.503-0.094-2.221-0.281s-1.352-0.481-1.9-0.883c-0.55-0.4-0.986-0.91-1.31-1.531s-0.478-1.357-0.466-2.211H142.46z"/>
			<path fill="#3A5967" d="M156.523,15.252h-4.15v-2.56h11.346v2.56h-4.15v11.287h-3.045V15.252z"/>
			<path fill="#3A5967" d="M165.796,12.692h3.045v13.847h-3.045V12.692z"/>
		</g>
		<path fill-rule="evenodd" clip-rule="evenodd" fill="#3A5967" d="M194.473,19.042h17.735c0-9.795-7.94-17.735-17.735-17.735   s-17.735,7.94-17.735,17.735s7.94,17.735,17.735,17.735V19.042z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" fill="#BE1E2D" d="M196.118,20.688v17.735c9.795,0,17.735-7.94,17.735-17.735   H196.118z"/>
	</g>
`]