import React, {useState, useEffect, useContext} from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
	CCol,
    CAlert,
    CButton,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CSpinner
} from '@coreui/react'



// import '../../conf/globals.js'




const Profile = props => {

	// context
    // const { status } = useContext(StatusContext);

    // dispatch
    const dispatch = useDispatch()
    const status = useSelector(state => state.status)

    console.log(status)

	// const user_cont = React.useContext(UserContext);
	// user_cont.name = "pera"

	const [userId, setUserId] = useState(false);
    const [form, setValue] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        roles: [],
    });
    // const [errorsTouch, setErrorsTouch] = useState(false);
    const [errors, setErrors] = useState({
        name: {msg: '', isValid: undefined, isInvalid: undefined},
        username: {msg: '', isValid: undefined, isInvalid: undefined},
        email: {msg: '', isValid: undefined, isInvalid: undefined},
        password: {msg: '', isValid: undefined, isInvalid: undefined},
        // touched: Date.now()
    });
    // console.log(form)
    const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);
    const [roles, setRoles] = useState({});
    const [changeRolesDisabled, setChangeRolesDisabled] = useState(true);

    const [dataWaiting, setDataWaiting] = useState(true);

	// const prepareAndSetValue = (item) => {

 //        let value_roles = [];
 //        let value = {}


 //        Object.assign(value, {name: item.name});
 //        Object.assign(value, {username: item.username});
 //        Object.assign(value, {email: item.email});
 //        Object.assign(value, {password: ''});
 //        Object.assign(value, {password_confirmation: ''});

 //        Object.keys(roles).map((column,index) => (
 //            value_roles.push({value: roles[column].id, isChecked: item.roles.some(element => element.id == roles[column].id)}) //item.roles.includes(roles[column])
 //        ))

 //        Object.assign(value, {roles: value_roles});


 //        setValue(value);
 //    }


    const editUser = async (e) => {
        // e.preventDefault();
        dumpErrors();
        // await fetch(url )
        let response = undefined;

        let roles = form.roles.filter(role => role.isChecked).map((column,index) => (column.value));

        let res = await fetch(global.baseUrl + "api/users/" + userId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "username": form.username, "email": form.email, "password": form.password, "password_confirmation": form.password_confirmation, "roles": roles})});
        response = await res.json();


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type === 'error') {
            
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {

                newErrors[column].isValid = response.errors[column] ? false : true;
                newErrors[column].isInvalid = response.errors[column] ? true : false;
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '';
            });

            setErrors({...errors, newErrors});
        } else if (response.type === 'success') {

        	let updatedUser = {...status.user, "name": form.name, "username": form.username, "email": form.email, "roles": roles};
            dispatch({type: 'set', status: {...status, user: updatedUser}});
        }
    }


    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }


  	// const [items, setItems] = useState(usersData)


  	useEffect(() => {
        	// hb();
        (async function () {
            setRoles(await getRoles());

        	
        })();
  	}, [])

  	// useEffect(() => {

  	// 	let value_roles = [];
   //      let value = {}


  	// }, [roles])

  	useEffect(() => {
  		if (typeof status !== 'undefined') {

  			let value_roles = [];
        	let newValue;

        	newValue = {
		        name: status.user.name,
		        username: status.user.username,
		        email: status.user.email,
		        password: '',
		        password_confirmation: '',
		        roles: []
	    	};

	        Object.keys(roles).map((column,index) => {
	            value_roles.push({value: roles[column].id, isChecked: status.user.roles.some(element => element == roles[column].id)}) //item.roles.includes(roles[column])
	        })

	        newValue = {...newValue, roles: value_roles};

	        setValue(newValue);

	        setUserId(status.user.id)

	        if (status.user.permissions.includes('Administrate')) {
	        	setChangeRolesDisabled(false);
	        }
  		}

  		if (typeof status !== 'undefined' && Object.keys(roles).length > 0) {
  			// console.log(roles)
  			setDataWaiting(false);
  		}

  	}, [status, roles])

  	const getRoles = async () => {
        let res = await fetch(global.baseUrl + "api/roles", {method: 'GET', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
        let roles = await res.json();
        return await roles;
    }

	const handleInputChange = (event) => {


        const target = event.target;
        const name = target.name;

        let value;

        if (target.type === 'checkbox') {

        console.log("marko")
            let index = form[name].findIndex(i => i.value === parseInt(target.value));
            let newValue = [...form[name]];

            if (target.checked) {

                newValue[index] = {...newValue[index], isChecked: true}

            } else {
                newValue[index] = {...newValue[index], isChecked: false}
            }

            value = newValue;

        } else {
            value = target.value;
        }

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

    // console.log(roles)
    if (dataWaiting) {
    	return (
    		<div className="mt-5 pt-5 text-center">
    			<CSpinner color="success" style={{width:'4rem', height:'4rem'}}/>
  			</div>
		)
    }


	return (

		<React.Fragment>
			<CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
	            {message}
	        </CAlert>
			<CForm className='col-lg-7' action="" method="post">
	            <CFormGroup>
	            {console.log(form)}
	                <CLabel htmlFor="name">Name</CLabel>
	                <CInput
	                type="text"
	                id="name"
	                name="name"
	                placeholder={"Enter Name.."}
	                autoComplete="text"
	                value={form.name}
	                onChange={(event) => handleInputChange(event)}
	                valid={errors.name.isValid}
	                invalid={errors.name.isInvalid}
	                />
	                <CValidFeedback>Input is valid!</CValidFeedback>
	                <CInvalidFeedback>{errors.name.msg}</CInvalidFeedback>
	                <CFormText className="help-block">Name</CFormText>
	            </CFormGroup>
	            <CFormGroup>
	                <CLabel htmlFor="name">Username</CLabel>
	                <CInput
	                type="text"
	                id="username"
	                name="username"
	                placeholder="Enter Username.."
	                autoComplete="text"
	                value={form.username}
	                onChange={(event) => handleInputChange(event)}
	                valid={errors.username.isValid}
	                invalid={errors.username.isInvalid}
	                />
	                <CValidFeedback>Input is valid!</CValidFeedback>
	                <CInvalidFeedback>{errors.username.msg}</CInvalidFeedback>
	                <CFormText className="help-block">Username</CFormText>
	            </CFormGroup>
	            <CFormGroup>
	                <CLabel htmlFor="email">Email</CLabel>
	                <CInput
	                type="email"
	                id="email"
	                name="email"
	                placeholder="Enter Email.."
	                autoComplete="email"
	                value={form.email}
	                onChange={(event) => handleInputChange(event)}
	                valid={errors.email.isValid}
	                invalid={errors.email.isInvalid}
	                />
	                <CValidFeedback>Input is valid!</CValidFeedback>
	                <CInvalidFeedback>{errors.email.msg}</CInvalidFeedback>
	                <CFormText className="help-block">Email</CFormText>
	            </CFormGroup>
	            <CFormGroup>
	                <CLabel htmlFor="password">New Password</CLabel>
	                <CInput
	                type="password"
	                id="password"
	                name="password"
	                placeholder="Enter New Password..."
	                autoComplete="password"
	                value={form.password}
	                onChange={(event) => handleInputChange(event)}
	                valid={errors.password.isValid}
	                invalid={errors.password.isInvalid}
	                />
	                <CValidFeedback>Input is valid!</CValidFeedback>
	                <CInvalidFeedback>{errors.password.msg}</CInvalidFeedback>
	                <CFormText className="help-block">Password</CFormText>
	            </CFormGroup>
	            <CFormGroup>
	                <CLabel htmlFor="password">Password confirmation</CLabel>
	                <CInput
	                type="password"
	                id="password-confirmation"
	                name="password_confirmation"
	                placeholder="Confirm Password..."
	                autoComplete="password-confirmation"
	                // value={form.password_confirmation}
	                onChange={(event) => handleInputChange(event)}
	                />
	                <CFormText className="help-block">Password</CFormText>
	            </CFormGroup>
	            <CFormGroup row>
	                <CCol tag="label" sm="3" className="col-form-label">
	                    Roles
	                </CCol>
	                <CCol sm="9" className='d-flex align-items-center'>
	                    {
	                        Object.keys(roles).map((column, index) => (
	                            <React.Fragment key={index}>
	                                <CSwitch
	                                  id={roles[column].name}
	                                  name='roles'
	                                  className="mr-2"
	                                  color="success"
	                                  checked={typeof form.roles[column] !== 'undefined' ? form.roles[column].isChecked : false}
	                                  size='sm'
	                                  variant='3d'
	                                  value={roles[column].id}
	                                  onChange={(event) => handleInputChange(event)}
	                                  disabled={changeRolesDisabled}
	                                />
	                               <CLabel className='align-top' style={{cursor: 'pointer'}} variant="checkbox" htmlFor={roles[column].name}>{roles[column].name}</CLabel>
	                            </React.Fragment>
	                        ))
	                    }
	                </CCol>
	            </CFormGroup>
	            <CButton color="primary" onClick={editUser}>Confirm</CButton>
	        </CForm>
		</React.Fragment>
	)
}

export default Profile