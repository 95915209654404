import React from 'react'

const Home = () => {
	return (
		<div>
			<h2>Welcome to KoJeNaVlasti 2.0 open beta!</h2>
		</div>
	)
}

export default Home