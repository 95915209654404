import { useState, useEffect, Suspense } from 'react'
import React from 'react'
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom'
import PrivateRoute from './auth/PrivateRoute'
import Layout from './components/layouts/Layout';
import Login from './components/pages/login/Login';
import Page500 from './components/pages/page500/Page500';
// import Page404 from './components/pages/page404/Page404';
import Heartbeat from './hb/Heartbeat';
import { useSelector, useDispatch } from 'react-redux';

import './conf/globals.js'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const Layout = React.lazy(() => import('./components/layouts/Layout'));

// Pages
// const Login = React.lazy(() => import('./components/pages/login/Login'));
const Page404 = React.lazy(() => import('./components/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./components/pages/page500/Page500'));

const App = () => {
    const [status, setAppStatus] = useState({
        isAuthenticated: '',
        user: {}
    });
    const [redirect, setRedirect] = useState(false);

    console.log(global.baseUrl)

    // dispatch
    const dispatch = useDispatch()

    useEffect(() => {
        console.log('ceo app');
        (
            async () => {

                const content = await heartBeat();

                let newStatus;

                if (content.isAuthenticated) {
                    newStatus = content;
                } else {
                    newStatus = {
                        isAuthenticated: false,
                        user: {
                            name: '',
                            username: '',
                            email: '',
                            password: '',
                            password_confirmation: '',
                            roles: [],
                        }
                    };
                }
                setAppStatus(newStatus);
                dispatch({type: 'set', status: newStatus});
            }
        )();
    }, []);

    const heartBeat = async () => {

        const response = await fetch(global.baseUrl + 'api/userAuth', { method: 'POST', headers: {'Content-Type': 'application/json'}, credentials: 'include' });

        const content = await response.json();

        return content;

    }

    if (status.isAuthenticated === '') {
        return loading;
    }

    return (
        <Router>
            <Heartbeat heartbeatFunction={heartBeat} heartbeatInterval={900000} />
            <Suspense fallback={loading}>
                <Switch>
                    <Route exact path="/login" name="Login" component={() => status.isAuthenticated ? <Redirect to='/' /> : <Login setAppStatus={setAppStatus}/>}/>
                    <PrivateRoute exact path="/dashboard" name="Dashboard" component={Page500} auth={status.isAuthenticated}/>
                    <Route path='/404' component={Page404} />
                    <PrivateRoute path="/" name="Home" component={() => <Layout status={status} setAppStatus={setAppStatus}/>} auth={status.isAuthenticated}/>
                </Switch>
            </Suspense>
        </Router>
    );
}

export default App;