import React, {useState, useEffect, useContext} from 'react'
import { useSelector, useDispatch } from 'react-redux'
//
import {
    CDataTable,
    CBadge,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch
} from '@coreui/react'
//
import DataTable from "../../reusable/DataTable/DataTable"

// import '../../conf/globals.js'

const Users = ({user}) => {


    // context
    // const { status, setAppStatus } = useContext(StatusContext);

	const [details, setDetails] = useState([])
 //    const [users, setUsers] = useState([])
	// const [pagination, setPagination] = useState({
 //        'activePage': 1,
 //        'pages': 1
 //    })
	const [loading, setLoading] = useState(true)
    // const [deleteActionCounter, setDeleteActionCounter] = useState(1)
    const [userModal, setUserModal] = useState(false);
    const [userId, setUserId] = useState(false);
    const [form, setValue] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        roles: [],
    });
    // const [errorsTouch, setErrorsTouch] = useState(false);
    const [errors, setErrors] = useState({
        name: {msg: '', isValid: undefined, isInvalid: undefined},
        username: {msg: '', isValid: undefined, isInvalid: undefined},
        email: {msg: '', isValid: undefined, isInvalid: undefined},
        password: {msg: '', isValid: undefined, isInvalid: undefined},
        // touched: Date.now()
    });
    // console.log(form)
    const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);
    const [all_roles, setRoles] = useState({});


    // dispatch
    const dispatch = useDispatch()
    const tableReload = useSelector(state => state.tableReload)
    const status = useSelector(state => state.status)
    // const statusContextTouch = useSelector(state => state.statusContextTouch)

    const editUser = async (e) => {
        // e.preventDefault();
        dumpErrors();
        // await fetch(url )
        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/users/" + userId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let roles = form.roles.filter(role => role.isChecked).map((column,index) => (column.value));

            let res = await fetch(global.baseUrl + "api/users/" + userId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "username": form.username, "email": form.email, "password": form.password, "password_confirmation": form.password_confirmation, "roles": roles})});
            response = await res.json();


            if (status.user.id == userId && response.type === 'success') {

            // if (user.id == userId) {
                // setAppStatus({
                //     isAuthenticated: true,
                //     user: {...status.user, "name": form.name, "username": form.username, "email": form.email, "roles": roles}
                // })
                console.log('user dispatch')
                console.log(status)
                let updatedUser = {...status.user, "name": form.name, "username": form.username, "email": form.email, "roles": roles};
                console.log(updatedUser)
                dispatch({type: 'set', status: {...status, user: updatedUser}});
                console.log(status)
                // dispatch({type: 'set', statusTouch: Date.now()});
            }


        } else if (modalType === 'create') {
            let roles = form.roles.filter(role => role.isChecked).map((column,index) => (column.value));

            let res = await fetch(global.baseUrl + "api/users/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "username": form.username, "email": form.email, "password": form.password, "password_confirmation": form.password_confirmation, "roles": roles})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);
        // console.log(response)
        // console.log(message)
        // console.log(response.type);
        // console.log(messageType)
        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
            // dumpMessage();
        } else {
            let newErrors = errors;
            // Object.keys(response.errors).map((column,index) => {
            Object.keys(newErrors).map((column,index) => {
                // console.log(column);
                // console.log(newErrors);
                // console.log(newErrors[column]);
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            // newErrors.touched = Date.now()
                // newErrors[column] = response.errors[column][0]
                // newErrors = [...newErrors, errors.name]

            // });
            // setNameInvalid(true);
            // setErrors(newErrors);
            setErrors({...errors, newErrors});
            // setErrorsTouch(Date.now());
            // forceUpdate();
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    // const clearForm = (e) => {
    //     setValue({
    //         name: '',
    //         username: '',
    //         email: '',
    //         password: '',
    //         roles: [],
    //     })
    // }

    const toggleModal = async (e) => {
        setUserModal(!userModal);
    }

    const handleInputChange = (event) => {


        const target = event.target;
        const name = target.name;

        let value;

        if (target.type === 'checkbox') {
            
            let index = form[name].findIndex(i => i.value === parseInt(target.value));
            let newValue = [...form[name]];

            if (target.checked) {

                newValue[index] = {...newValue[index], isChecked: true}

            } else {
                newValue[index] = {...newValue[index], isChecked: false}
            }

            value = newValue;

        } else {
            value = target.value;
        }

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

    const prepareAndSetValue = (item, typeOfModal) => {

        let value_roles = [];
        let value = {}

        if (typeOfModal == 'update') {


            Object.assign(value, {name: item.name});
            Object.assign(value, {username: item.username});
            Object.assign(value, {email: item.email});
            Object.assign(value, {password: ''});
            Object.assign(value, {password_confirmation: ''});


            // item.roles.map((column,index) => (
            //     value_roles.push(column.id)
            // ));

            // console.log(roles)
            // console.log(item.roles)
            Object.keys(all_roles).map((column,index) => (
                value_roles.push({value: all_roles[column].id, isChecked: item.roles.some(element => element.id == all_roles[column].id)}) //item.roles.includes(roles[column])
            ))
        } else if (typeOfModal == 'create') {

            Object.assign(value, {name: ''});
            Object.assign(value, {username: ''});
            Object.assign(value, {email: ''});
            Object.assign(value, {password: ''});
            Object.assign(value, {password_confirmation: ''});

            Object.keys(all_roles).map((column,index) => (
                value_roles.push({value: all_roles[column].id, isChecked: false}) //item.roles.includes(roles[column])
            ))
        }

        Object.assign(value, {roles: value_roles});


        setValue(value);
    }


  	// const [items, setItems] = useState(usersData)


  	useEffect(() => {
        (async function () {
      		// let users = await getUsers()

        //     console.log(users.meta.current_page)

        //     setUsers(users.data)
        //     setPagination({
        //         'activePage': users.meta.current_page,
        //         'pages': users.meta.last_page
        //     })

            setRoles(await getRoles());

    		setLoading(false)

        })();
  	}, [])

    // useEffect(() => {
    //     // (async function () {
    //     //  // console.log("usamljeni useEffect")

    //     // })();
    // }, [errorsTouch, errors]) // ovde mozda state formSubmited da se setuje sa vremenom na svaki submit

    const getRoles = async () => {
        let res = await fetch(global.baseUrl + "api/roles", {method: 'GET', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
        let roles = await res.json();
        return await roles;
    }

    // const paginateData = async (page, itemsPerPage, orderBy = false, order = "ASC", search = false) => {

    //     console.log(search)

    //     let users = await getUsers(page, itemsPerPage, orderBy, order, search)

    //     setUsers(users.data)
    //     setPagination({
    //         'activePage': users.meta.current_page,
    //         'pages': users.meta.last_page
    //     })
    // }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}


	const fields = [
    	{ key: 'name', _style: { width: '40%'} },
    	'email',
    	{ key: 'roles.name', sorter: false, _style: { width: '20%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

	// const getUsers = async (page = 1, itemsPerPage = 10, orderBy = false, order = "ASC", search = false) => {

 //        // console.log(itemsPerPage)

 //        let body = {
 //            "page": page,
 //            "itemsPerPage": itemsPerPage,
 //        }

 //        if (orderBy) {
 //            Object.assign(body, {"order_by": orderBy, "order": order})
 //        }

 //        if (search) {
 //            Object.assign(body, {"searchCol": search})
 //        }

	// 	const res = await fetch(
 //            global.baseUrl + "api/users",
 //            {
 //                method: 'POST',
 //                headers: { 'Content-Type': 'application/json' },
 //                credentials: 'include',
 //                body: JSON.stringify(body)
 //            });




	// 	const users = await res.json();

	// 	return await users

	// 		// console.log(users)
	// }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	const getBadge = (status)=>{
		switch (status) {
  			case 'Active': return 'success'
  			case 'Inactive': return 'secondary'
  			case 'Pending': return 'warning'
  			case 'Banned': return 'danger'
  			default: return 'primary'
		}
	}
{
    // server_side
    // server_side={pagination}
    //         paginateData={paginateData}
    //options={method: 'POST'}
    // items={users}
}
	return (
        <div>
            <div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new user</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
        	<DataTable
        		loading
          		
          		fields={fields}
          		columnFilter
          		tableFilter
          		footer
          		itemsPerPageSelect
                itemsPerPage={10}
          		hover
          		sorter
          		pagination
                server_side
                url={global.baseUrl + "api/users"}
                options={options}
          		scopedSlots = {{
            		'status':
              		(item)=>(
    	            	<td>
    	              		<CBadge color={getBadge(item.status)}>
    	                		{item.status}
    	              		</CBadge>
    	            	</td>
              		),
              		'roles.name':
              		(item, index)=>{
              			let user_roles = item.roles && item.roles.map( role => {
          						return role.name
          				})
          				// console.log(roles)

              			return (
              				<td className="py-2">
              				{user_roles.join(', ')}
              				</td>
          				)
              		},
            		'show_details':
              		(item, index)=>{
                		return (
                  			<td className="py-2">
                    			<CButton
                  				color="primary"
                      			variant="outline"
                      			shape="square"
                      			size="sm"
                      			onClick={()=>{toggleDetails(index)}}
                    			>
                      				{details.includes(index) ? 'Hide' : 'Show'}
                    			</CButton>
                  			</td>
                  		)
              		},
            		'details':
                	(item, index)=>{
                  		return (
                  			<CCollapse show={details.includes(index)}>
                    			<CCardBody>
                                    {item.id}
                      				<h4>
                        			{item.username}
                      				</h4>
                      				<p className="text-muted">User since: {item.registered}</p>
                      				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setUserId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                        				User Settings
                      				</CButton>
                      				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setUserId(item.id);}}>
                        				Delete
                      				</CButton>
                    			</CCardBody>
                  			</CCollapse>
                		)
              		}
          		}}
        	/>

            <CModal
                show={userModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete user' : modalType === 'update' ? 'Update user' : 'Add new user'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove user?' : 
                    <CForm action="" method="post"  onSubmit={editUser}>
                        <CFormGroup>
                            <CLabel htmlFor="name">Name</CLabel>
                            <CInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder={"Enter Name.."}
                            autoComplete="text"
                            value={form.name}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.name.isValid}
                            invalid={errors.name.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.name.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Name</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="name">Username</CLabel>
                            <CInput
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Enter Username.."
                            autoComplete="text"
                            value={form.username}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.username.isValid}
                            invalid={errors.username.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.username.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Username</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="email">Email</CLabel>
                            <CInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter Email.."
                            autoComplete="email"
                            value={form.email}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.email.isValid}
                            invalid={errors.email.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.email.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Email</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="password">New Password</CLabel>
                            <CInput
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Enter New Password..."
                            autoComplete="password"
                            value={form.password}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.password.isValid}
                            invalid={errors.password.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.password.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Password</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="password">Password confirmation</CLabel>
                            <CInput
                            type="password"
                            id="password-confirmation"
                            name="password_confirmation"
                            placeholder="Confirm Password..."
                            autoComplete="password-confirmation"
                            value={form.password_confirmation}
                            onChange={(event) => handleInputChange(event)}
                            />
                            <CFormText className="help-block">Password</CFormText>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol tag="label" sm="3" className="col-form-label">
                                Roles
                            </CCol>
                            <CCol sm="9" className='d-flex align-items-center'>
                                {
                                    Object.keys(all_roles).map((column, index) => (
                                        <React.Fragment key={index}>
                                            <CSwitch
                                              id={all_roles[column].name}
                                              name='roles'
                                              className="mr-2"
                                              color="success"
                                              checked={typeof form.roles[column] !== 'undefined' ? form.roles[column].isChecked : false}
                                              size='sm'
                                              variant='3d'
                                              value={all_roles[column].id}
                                              onChange={(event) => handleInputChange(event)}
                                            />
                                           <CLabel className='align-top' style={{cursor: 'pointer'}} variant="checkbox" htmlFor={all_roles[column].name}>{all_roles[column].name}</CLabel>
                                        </React.Fragment>
                                    ))
                                }
                            </CCol>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editUser}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
        </div>
  	)
}

export default Users