import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
    CTextarea,
    CSelect
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import Select2 from "../../../reusable/Select2/Select2"
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Elections = () => {

	// const [actors, setActors] = useState();
    // const [all_genders, setGenders] = useState({});
    // const [all_professions, setProfessions] = useState({});

	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        title: '',
        election_date: null,
        election_level_id: '',
        election_type_id: '',
    });

    // const [select2Values, setSelect2Values] = useState({
    //     gender: null,
    //     profession: null,
    // })
    const [select2Item, setSelect2Item] = useState({});

    const [errors, setErrors] = useState({
        title: {msg: '', isValid: undefined, isInvalid: undefined},
        election_date: {msg: '', isValid: undefined, isInvalid: undefined},
        election_level_id: {msg: '', isValid: undefined, isInvalid: undefined},
        election_type_id: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [electionModal, setElectionModal] = useState(false);
	const [electionId, setElectionId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

    const [electionDate, setElectionDate] = useState(null);

     // dispatch
    const dispatch = useDispatch()

    const editElection = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/elections/" + electionId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let res = await fetch(global.baseUrl + "api/elections/" + electionId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"title": form.title, "election_date": form.election_date, election_level_id: form.election_level_id, election_type_id: form.election_type_id})});
            response = await res.json();


        } else if (modalType === 'create') {

            let res = await fetch(global.baseUrl + "api/elections/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"title": form.title, "election_date": form.election_date, election_level_id: form.election_level_id, election_type_id: form.election_type_id})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {

        console.log(event)

        const target = event.target;
        const name = target.name;

        let value = target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setElectionModal(!electionModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {

            Object.assign(value, {title: item.title});
            Object.assign(value, {election_date: item.election_date});
            Object.assign(value, {election_level_id: item.election_level_id});
            Object.assign(value, {election_type_id: item.election_type_id});

        	setElectionDate(new Date(item.election_date));

            setSelect2Item({
                election_level: {label: item.election_level.level, value: item.election_level_id},
                election_type: {label: item.election_type.type, value: item.election_type_id}
            })

        } else if (typeOfModal == 'create') {

            Object.assign(value, {title: ''});
            Object.assign(value, {election_date: null});
            Object.assign(value, {election_level_id: ''});
            Object.assign(value, {election_type_id: ''});

            setElectionDate(null);

            setSelect2Item({
                election_level: null,
                election_type: null
            })
        }


        setValue(value);
    }


	const fields = [
    	{ key: 'title', _style: { width: '20%'} },
    	{ key: 'election_date', _style: { width: '20%'} },
    	{ key: 'election_level.level', _style: { width: '20%'} },
    	{ key: 'election_type.type', _style: { width: '20%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new election</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		columnFilter
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
      		pagination
            server_side
            url={global.baseUrl + "api/elections"}
            options={options}
      		scopedSlots = {{
      			'election_date':
          		(item, index)=>{
            		return (
              			<td>
                			{moment(item.election_date).format('DD.MM.YYYY.')}
              			</td>
              		)
          		},
      			'election_level.level':
          		(item, index)=>{
            		return (
              			<td>
                			{item.election_level.level}
              			</td>
              		)
          		},
          		'election_type.type':
          		(item, index)=>{
            		return (
              			<td>
                			{item.election_type.type}
              			</td>
              		)
          		},
        		'show_details':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			<CButton
              				color="primary"
                  			variant="outline"
                  			shape="square"
                  			size="sm"
                  			onClick={()=>{toggleDetails(index)}}
                			>
                  				{details.includes(index) ? 'Hide' : 'Show'}
                			</CButton>
              			</td>
              		)
          		},
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setElectionId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Election Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setElectionId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={electionModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete election' : modalType === 'update' ? 'Update election' : 'Add new election'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove election?' : 
                    <CForm action="" method="post">
                    	<CFormGroup>
                            <CLabel htmlFor="title">Title</CLabel>
                            <CInput
                            type="text"
                            id="title"
                            name="title"
                            placeholder={"Enter Title.."}
                            autoComplete="text"
                            value={form.title}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.title.isValid}
                            invalid={errors.title.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.title.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Title</CFormText>
                        </CFormGroup>
                    	<CFormGroup>
                            <CLabel htmlFor="election-date">Election date</CLabel>
                            <div className="knv-react-datepicker-wrapper">
	                            <DatePicker
	                            id="election-date"
	                        	name="election_date"
				      			selected={electionDate}
						      	onChange={(date) => {setElectionDate(date);setValue(prev => ({...prev, election_date: moment(date).format('YYYY-MM-DD')}));}}
						      	// showYearPicker
						      	dateFormat="dd.MM.yyyy."
						      	className="form-control w-100"
							    />
                            </div>
                        	<div>
                        	{
                        		typeof errors.election_date === 'undefined' ?

                            	<small className="text-success">Input is valid!</small>
                        		:
                            	<small className="text-danger">{errors.election_date.msg}</small>

                        	}
                        	</div>
                            <CFormText className="help-block">Election date</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="profession">Election level</CLabel>
                            <Select2
                            url={global.baseUrl + "api/election_levels"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'level'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.election_level && select2Item.election_level) }
                            onChange={(value) => {setValue(prev => ({ ...prev, election_level_id: value }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="election_level"
                            />
                            {
                                typeof errors.election_level_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.election_level_id.msg}</small>

                            }
                            <CFormText className="help-block">Election level</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="gender">Election type</CLabel>
                            <Select2
                            url={global.baseUrl + "api/election_types"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'type'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.election_type && select2Item.election_type) }
                            onChange={(value) => {setValue(prev => ({ ...prev, election_type_id: value, }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="election_type"
                            />
                            {
                                typeof errors.election_type_id === 'undefined' ?

                                <small className="text-success">Input is valid!</small>
                                :
                                <small className="text-danger">{errors.election_type_id.msg}</small>

                            }
                            <CFormText className="help-block">Election type</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editElection}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Elections