import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
    CDataTable,
    CButton,
    CCollapse,
    CCardBody,
    CCol,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CAlert,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CFormText,
    CInputCheckbox,
    CValidFeedback,
    CInvalidFeedback,
    CSwitch,
    CRow,
} from '@coreui/react'
import DataTable from "../../../reusable/DataTable/DataTable"
import Select2 from "../../../reusable/Select2/Select2"
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import '../../../conf/globals.js'

const Coalitions = () => {
    
	const [details, setDetails] = useState([])

	const [form, setValue] = useState({
        name: '',
        start_date: null,
        end_date: null,
        parties: []
    });

    const [select2Item, setSelect2Item] = useState({});

    const [errors, setErrors] = useState({
        name: {msg: '', isValid: undefined, isInvalid: undefined},
        start_date: {msg: '', isValid: undefined, isInvalid: undefined},
        end_date: {msg: '', isValid: undefined, isInvalid: undefined},
    });

	const [coalitionModal, setCoalitionModal] = useState(false);
	const [coalitionId, setCoalitionId] = useState(false);
	const [modalType, setModalType] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState(undefined);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

     // dispatch
    const dispatch = useDispatch()

    const editParty = async (e) => {

        dumpErrors();

        let response = undefined;

        if (modalType === 'delete') {
            let res = await fetch(global.baseUrl + "api/coalitions/" + coalitionId, {method: 'DELETE', headers: { 'Content-Type': 'application/json' }, credentials: 'include'});
            response = await res.json();
        } else if (modalType === 'update') {

            let res = await fetch(global.baseUrl + "api/coalitions/" + coalitionId, {method: 'PUT', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "start_date": form.start_date, "end_date": form.end_date, "parties": form.parties})});
            response = await res.json();


        } else if (modalType === 'create') {

        	console.log(form.parties);

            let res = await fetch(global.baseUrl + "api/coalitions/add", {method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: JSON.stringify({"name": form.name, "start_date": form.start_date, "end_date": form.end_date, "parties": form.parties})});
            response = await res.json();
        }


        (typeof response !== 'undefined') && setMessage(response.message);
        (typeof response !== 'undefined') && setMessageType(response.type);

        if (response.type !== 'error') {
            toggleModal();
            setDetails([]);
            dispatch({type: 'set', tableReload: Date.now()})
        } else {
            let newErrors = errors;

            Object.keys(newErrors).map((column,index) => {
                newErrors[column].isValid = response.errors[column] ? false : true //response.errors[column][0]
                newErrors[column].isInvalid = response.errors[column] ? true : false //response.errors[column][0]
                newErrors[column].msg = response.errors[column] ? response.errors[column][0] : '' //response.errors[column][0]
            });

            setErrors({...errors, newErrors});
        }
    }

    const dumpMessage = (e) =>{

        !e && setMessage(false);
        !e && setMessageType(undefined);
    }

    const dumpErrors = (e) =>{
        let cleanErrors = errors;

        Object.keys(cleanErrors).map((column,index) => {
            cleanErrors[column] = {msg: '', isValid: undefined, isInvalid: undefined};
        });

        setErrors(cleanErrors);
    }

    const handleInputChange = (event) => {



        const target = event.target;
        const name = target.name;

        let value = target.value;

        setValue(prev => ({ 
            ...prev,
            [name]: value,
        }));
    }

	const toggleDetails = (index) => {
    	const position = details.indexOf(index)
    	let newDetails = details.slice()
    	if (position !== -1) {
      		newDetails.splice(position, 1)
    	} else {
      		newDetails = [...details, index]
    	}
    	setDetails(newDetails)
	}

	const toggleModal = async (e) => {
        setCoalitionModal(!coalitionModal);
    }


    const prepareAndSetValue = (item, typeOfModal) => {

        let value = {}

        if (typeOfModal == 'update') {


            Object.assign(value, {name: item.name});
            Object.assign(value, {start_date: item.start_date});
            Object.assign(value, {end_date: item.end_date});

            setStartDate(item.start_date ? new Date(item.start_date) : null);
        	setEndDate(item.end_date ? new Date(item.end_date) : null);

        	let parties_values = [];

        	item.parties.map((column) => parties_values.push(column.id));

            Object.assign(value, {parties: parties_values});

        	let parties = [];

        	item.parties.map((column) => parties.push({label: column.name, value:column.id}));

        	// console.log(item.parties)

            setSelect2Item({parties: parties});


        } else if (typeOfModal == 'create') {

            Object.assign(value, {name: ''});
            Object.assign(value, {start_date: null});
            Object.assign(value, {end_date: null});
            Object.assign(value, {parties: []});

            setStartDate(null);
        	setEndDate(null);

            setSelect2Item({
                parties: null,
                // election: null,
                // territory: null
            })
        }


        setValue(value);
            // console.log(form)
    }


	const fields = [
    	{ key: 'name', _style: { width: '40%'} },
    	{ key: 'start_date', _style: { width: '20%'} },
    	{ key: 'end_date', _style: { width: '20%'} },
    	{
	      	key: 'show_details',
	      	label: '',
	      	_style: { width: '1%' },
	      	sorter: false,
	      	filter: false
    	}
	]

    const options = {
        credentials: 'include'
    }

    const alertColors = {
        success: 'success',
        error: 'danger'
    }

	return (
		<div>
			<div className="row">
                <div className="col-md-3 justify-content-center align-self-center">
                    <CButton className="my-2" color="success" onClick={(e) => {toggleModal(); setModalType('create'); prepareAndSetValue(null, 'create'); dumpErrors();}}>Add new party</CButton>
                </div>
                <div className="col-md-9 justify-content-center align-self-center">
                    <CAlert className="m-0" show={!!message} color={alertColors[messageType]} onShowChange={dumpMessage} closeButton>
                        {message}
                    </CAlert>
                </div>
            </div>
			<DataTable
    		loading
      		fields={fields}
      		tableFilter
      		footer
      		itemsPerPageSelect
            itemsPerPage={10}
      		hover
      		sorter
      		pagination
            server_side
            url={global.baseUrl + "api/coalitions"}
            options={options}
      		scopedSlots = {{
      			'start_date':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			{item.start_date && moment(item.start_date).format('DD.MM.YYYY.')}
              			</td>
              		)
          		},
          		'end_date':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			{item.end_date && moment(item.end_date).format('DD.MM.YYYY.')}
              			</td>
              		)
          		},
        		'show_details':
          		(item, index)=>{
            		return (
              			<td className="py-2">
                			<CButton
              				color="primary"
                  			variant="outline"
                  			shape="square"
                  			size="sm"
                  			onClick={()=>{toggleDetails(index)}}
                			>
                  				{details.includes(index) ? 'Hide' : 'Show'}
                			</CButton>
              			</td>
              		)
          		},
        		'details':
            	(item, index)=>{
              		return (
              			<CCollapse show={details.includes(index)}>
                			<CCardBody>
                                <CRow>
                                    <CCol>
                                        <ul>
                                            {item.parties.map( (column, index) =>
                                                <li key={index}>{column.name}</li>
                                            )}
                                        </ul>
                                    </CCol>
                                </CRow>
                  				<CButton size="sm" color="info" onClick={(e) => {toggleModal(); setModalType('update'); setCoalitionId(item.id);prepareAndSetValue(item, 'update'); dumpErrors();}}>
                    				Coalition Settings
                  				</CButton>
                  				<CButton size="sm" color="danger" className="ml-1" onClick={(e) => {toggleModal(); setModalType('delete');  setCoalitionId(item.id);}}>
                    				Delete
                  				</CButton>
                			</CCardBody>
              			</CCollapse>
            		)
          		}
      		}}
    		/>
    		<CModal
                show={coalitionModal}
                onClose={toggleModal}
            >
                <CModalHeader closeButton>
                    {modalType === 'delete' ? 'Delete coalition' : modalType === 'update' ? 'Update coalition' : 'Add new coalition'}
                </CModalHeader>
                <CModalBody>
                    {modalType === 'delete' ? 'Are you sure that you want to remove coalition?' : 
                    <CForm action="" method="post">
                        <CFormGroup>
                            <CLabel htmlFor="name">Name</CLabel> <small><i className="text-muted">required</i></small>
                            <CInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder={"Enter Name.."}
                            autoComplete="text"
                            value={form.name}
                            onChange={(event) => handleInputChange(event)}
                            valid={errors.name.isValid}
                            invalid={errors.name.isInvalid}
                            />
                            <CValidFeedback>Input is valid!</CValidFeedback>
                            <CInvalidFeedback>{errors.name.msg}</CInvalidFeedback>
                            <CFormText className="help-block">Name</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="start-date">Start date</CLabel>
                            <div className="knv-react-datepicker-wrapper">
	                            <DatePicker
	                            id="start-date"
	                        	name="start_date"
				      			selected={startDate}
						      	onChange={(date) => {setStartDate(date);setValue(prev => ({...prev, start_date: moment(date).format('YYYY-MM-DD')}));}}
						      	// showYearPicker
						      	dateFormat="dd.MM.yyyy."
						      	className="form-control w-100"
							    />
                            </div>
                        	<div>
                        	{
                        		typeof errors.start_date === 'undefined' ?

                            	<small className="text-success">Input is valid!</small>
                        		:
                            	<small className="text-danger">{errors.start_date.msg}</small>

                        	}
                        	</div>
                            <CFormText className="help-block">Start date</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="end-date">End date</CLabel>
                            <div className="knv-react-datepicker-wrapper">
	                            <DatePicker
	                            id="end-date"
	                        	name="end_date"
				      			selected={endDate}
						      	onChange={(date) => {setEndDate(date);setValue(prev => ({...prev, end_date: moment(date).format('YYYY-MM-DD')}));}}
						      	// showYearPicker
						      	dateFormat="dd.MM.yyyy."
						      	className="form-control w-100"
							    />
                            </div>
                        	<div>
                        	{
                        		typeof errors.end_date === 'undefined' ?

                            	<small className="text-success">Input is valid!</small>
                        		:
                            	<small className="text-danger">{errors.end_date.msg}</small>

                        	}
                        	</div>
                            <CFormText className="help-block">End date</CFormText>
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="party">Parties</CLabel>
                            <Select2
                            id="party"
                            url={global.baseUrl + "api/political_parties"}
                            options={{credentials: 'include'}}
                            customKeys={{key:'id', label:'name'}}
                            dataKey="data"
                            className="form-control"
                            value={ select2Item && (select2Item.parties && select2Item.parties) }
                            onChange={(value) => {console.log(value); setValue(prev => ({ ...prev, parties: value, }));}}
                            setter={setSelect2Item}
                            isClearable={false}
                            keyToUpdate="parties"
                            isMulti
                            />
                            <CFormText className="help-block">Parties</CFormText>
                        </CFormGroup>
                    </CForm>

                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={editParty}>Confirm</CButton>{' '}
                    <CButton
                        color="secondary"
                        onClick={toggleModal}
                    >Cancel</CButton>
                </CModalFooter>
            </CModal>
		</div>
	)
}

export default Coalitions