import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarFooter,
  CSpinner
} from '@coreui/react'
// import { freeSet } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

// sidebar nav config
// import navigation from '../../conf/_sidebar_nav'
import {SidebarConf} from '../../conf/Conf'

const Sidebar = () => {

	const [navigation, setNavigation] = useState()

	const dispatch = useDispatch()
  	const show = useSelector(state => state.sidebarShow)
  	const status = useSelector(state => state.status)


  	useEffect( async () => {
  		if (typeof status !== 'undefined') {
  			setNavigation(await SidebarConf(status.user.permissions))
  		}
  	}, [status])
  	// console.log(navigation);

  // 	if (typeof navigation !== 'undefined' && navigation) {
  //   	return (
  //   		<div className="mt-5 pt-5 text-center">
  //   			<CSpinner color="success" style={{width:'4rem', height:'4rem'}}/>
  // 			</div>
		// )
  //   }

	return (
		<CSidebar show={show} onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}>
	      	<CSidebarBrand className="d-md-down-none" to="/">
	      		{/*<CIcon content={freeSet.cilSettings} />*/}
	        	<CIcon
	          	className="c-sidebar-brand-full"
	          	name="logo-sidebar"
	          	height={35}
	        	/>
	        	<CIcon
	          	className="c-sidebar-brand-minimized"
	          	name="logo-sidebar-minimized"
	          	height={35}
	        	/>
	        	{/*<h2 className="c-sidebar-brand-full">Ko Je Na Vlasti</h2>
	        	<h2 className="c-sidebar-brand-minimized">KNV</h2>*/}
	      	</CSidebarBrand>
      		<CSidebarNav>

	        	<CCreateElement items={navigation || []} components={{
	            		CSidebarNavDivider,
	            		CSidebarNavDropdown,
	            		CSidebarNavItem,
	            		CSidebarNavTitle
	          		}}
	        	/>
	      	</CSidebarNav>
	      	<CSidebarMinimizer className="c-d-md-down-none"/>
	    </CSidebar>
	)
}

export default Sidebar
// export default React.memo(Sidebar)