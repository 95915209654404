import React, {useEffect} from 'react'
import Sidebar from '../includes/Sidebar'
import Header from '../includes/Header'
import Content from './Content'

const Layout = ({user, hb, status, setAppStatus}) => {
	return (
		<div className="c-app c-default-layout">
      		<Sidebar />
            <div className="c-wrapper">
                {/*<StatusProvider value={status}>*/}
      				<Header setAppStatus={setAppStatus}/>
        			<div className="c-body">
          				<Content user={user} hb={hb}/>
        			</div>
                {/*</StatusProvider>*/}
    			{/*<TheFooter/>*/}
  			</div>
		</div>
	)
}

export default Layout