import React, {useState, useEffect} from 'react'
import AsyncSelect from 'react-select/async';

const Select2 = (props) => {

	const {
		url,
		options,
		customKeys,
		dataKey,
		value,
		onChange,
		isClearable,
        setter,
        keyToUpdate,
        isMulti,
        colSearch
	} = props

	const [inputValue, setValue] = useState({});
	const [select2Value, setSelect2Value] = useState(null);
	const [defaultOptions, setDefaultOptions] = useState([]);

	useEffect(() => {
		 (async function () {
		 	let prepared_data = await getOptions();
            setDefaultOptions(prepared_data);
        })();
	}, [])


	useEffect(() => {
        setSelect2Value(value);
	}, [value])


	const getOptions = async (limit = 10, orderBy = false, order = "ASC", search = false) => {
		const {
            method = 'POST',
            mode,
            cache,
            credentials,
            headers = { 'Content-Type': 'application/json' },
            redirect,
            referrerPolicy,
            body = {}
        } = options

	 	const {
	 		key,
	 		label
	 	} = customKeys

        let fetch_options = {
            method: method,
            headers: headers
        }

        if (mode) {
            Object.assign(fetch_options, {"mode": mode})
        }

        if (cache) {
            Object.assign(fetch_options, {"cache": cache})
        }

        if (credentials) {
            Object.assign(fetch_options, {"credentials": credentials})
        }

        if (redirect) {
            Object.assign(fetch_options, {"redirect": redirect})
        }

        if (referrerPolicy) {
            Object.assign(fetch_options, {"referrerPolicy": referrerPolicy})
        }

        Object.assign(body, {"limit": limit})

        if (orderBy) {
            Object.assign(body, {"order_by": orderBy, "order": order})
        }

        if (search && !colSearch) {
            Object.assign(body, {"search": search})
        } else if (search && colSearch) {
            Object.assign(body, {"searchCol": {[colSearch]: search}})
        }


        Object.assign(fetch_options, {body: JSON.stringify(body)})


        const res = await fetch(url, fetch_options);
        const raw_data = await res.json();

	 	let prepared_data = [];

	 	let temp_data = typeof raw_data[dataKey] !== 'undefined' ? raw_data[dataKey] : raw_data;

	 	temp_data.map((column,index) => {
	 		prepared_data.push({value: column[key], label: column[label]});
	 	});

        return await prepared_data
	}

    const loadOptions = async (inputValue, callback) => {
    	if (inputValue.length > 1) {
        	callback(await getOptions(10, false, "ASC", inputValue));
    	}
    };

    const handleInputChange = (search_value) => {
    	setValue(search_value);
    }

    const setInnerValue = (value) => {
    	setSelect2Value(value);
        console.log(select2Value)
    }

    const handleNewData = (value) => {
        setter(prev => ({...prev, [keyToUpdate]: value}));
    }

    const handleOnChange = (value) => {
        console.log(value)
        console.log(isMulti)
        if (isMulti) {
            let values = [];
            value.map((column) => values.push(column.value));
            console.log(values)
            onChange(values);
        } else {
            onChange(value ? value.value : null);
        }
    }


	return (
        <AsyncSelect
        cacheOptions
        value={select2Value} //{ label: "Select Dept", value: 0 }
        loadOptions={loadOptions}
        defaultOptions={defaultOptions} // const options = [{ value: 'chocolate', label: 'Chocolate' },{ value: 'strawberry', label: 'Strawberry' },{ value: 'vanilla', label: 'Vanilla' }]
        onInputChange={(search_value) => {handleInputChange(search_value);}}
        onChange={(value) => {handleOnChange(value);setInnerValue(value);handleNewData(value)}}
        isClearable={isClearable}
        isMulti={isMulti}
        />
	)
}

export default Select2